import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 bg-everMain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Navbar, { "scroll-top": _ctx.scrollTop }, null, 8, ["scroll-top"]),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer, {
      id: "footer",
      class: "flex-0"
    })
  ], 64))
}