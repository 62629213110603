
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Navbar from '@/components/Navbar/index.vue'
import Container from '@/components/common/Container.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  components: {
    Navbar,
    Container,
    Footer
  },
  setup () {
    const { t } = useI18n()
    const scrollTop = ref(0)
    const windowWidth = ref(document.documentElement.offsetWidth)
    onMounted(async () => {
      window.addEventListener('scroll', () => {
        scrollTop.value = document.documentElement.scrollTop
      })
      window.addEventListener('resize', () => {
        windowWidth.value = document.documentElement.offsetWidth
      })
    })
    return {
      t,
      scrollTop
    }
  }
})

