import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container mx-auto" }
const _hoisted_2 = {
  key: 0,
  class: "w-full pb-4 mb-6 border-b border-solid border-everGray1"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row items-center justify-between text-everPurple2 text-22px"
}
const _hoisted_4 = { class: "flex flex-row items-center justify-start font-medium" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex-1 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex flex-col main mx-auto shadow rounded-xl px-8 py-10 box-border bg-white w-full max-w-414px relative",
      style: _normalizeStyle([{"margin-bottom":"160px","box-shadow":"0px 4px 42px #F3F1F8"}, `min-height: ${_ctx.isLarge ? 750 : 540}px;`])
    }, [
      (_ctx.title || _ctx.$slots.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.title || _ctx.$slots.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.needBack)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: require('../../images/back.svg'),
                          class: "block mr-2 w-6 h-6 cursor-pointer",
                          alt: "back",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
                        }, null, 8, _hoisted_5))
                      : _createCommentVNode("", true),
                    (_ctx.$slots.title)
                      ? _renderSlot(_ctx.$slots, "title", { key: 1 })
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.title), 1))
                  ]),
                  (_ctx.$slots.headerRight)
                    ? _renderSlot(_ctx.$slots, "headerRight", { key: 0 })
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots.noticelist)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.$slots.noticelist)
                    ? _renderSlot(_ctx.$slots, "noticelist", { key: 0 })
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.affix)
        ? _renderSlot(_ctx.$slots, "affix", { key: 1 })
        : _createCommentVNode("", true)
    ], 4)
  ]))
}