
import { ref, computed, onMounted, watch, defineComponent, Ref } from 'vue'
import ScrollPicker from 'vue3-scroll-picker'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { isProd, everpayOfficialWebsiteHost, savedI18nStorageKey, isMobile, isEverpayApp, isEverpayFarm, isEverpayOfficialWebsite } from '@/constants'
import { checkParentsHas } from '@/libs/util'

interface navItems {
  title: string
  routeNames: string[]
  to:string
  openNew?: boolean
}
export default defineComponent({
  components: {
    ScrollPicker
  },
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    prefixLogo: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnStyleType: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['handleLocaleChange', 'handleBtnClick'],
  setup (props, context) {
    const isLangWrapperTarget = checkParentsHas('lang-wrapper')
    const isResourcesWrapperTarget = checkParentsHas('resources-wrapper')
    const { t, locale } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const routeName = computed(() => {
      return route.name as string
    })
    const windowWidth = ref(document.documentElement.offsetWidth)
    const mobileNavModalOpened = ref(false)
    const languagesVisible = ref(false)
    const resourcesVisible = ref(false)
    const navbarTextColorClass = isEverpayFarm ? 'text-everPurple9' : 'text-everPurple0'
    const navbarTextColorHoverClass = isEverpayFarm ? 'hover:text-everPurple9' : 'hover:text-everPurple0'
    const navbarBtnBorderClass = isEverpayFarm ? 'border-everPurple9' : 'border-everPurple0'
    const navbarBtnBackClass = isEverpayFarm ? 'connect-btn-bg-farm' : 'connect-btn-bg-normal'
    const navItems:Ref<navItems[]> = computed(() => {
      return [
        // {
        //   title: 'bridge',
        //   routeNames: isEverpayApp ? ['deposit', 'withdraw'] : [],
        //   openNew: !isEverpayApp,
        //   to: `${everpayAppHost}/deposit`
        // },
        // {
        //   title: 'wallet',
        //   routeNames: isEverpayApp ? ['transfer', 'assets', 'transactions', 'contacts'] : [],
        //   openNew: !isEverpayApp,
        //   to: `${everpayAppHost}/`
        // },
        // {
        //   title: 'swap',
        //   routeNames: isEverpayApp ? ['swap', 'swap_records'] : [],
        //   openNew: !isEverpayApp,
        //   to: `${everpayAppHost}/swap`
        // },
        // {
        //   title: 'NFT_auction',
        //   routeNames: isEverpayApp ? ['nftAuction', 'nftAuctionDetail', 'nftAuctionPast', 'nftAuctionBatch'] : [],
        //   openNew: !isEverpayApp,
        //   to: `${everpayAppHost}/nft-auction`
        // },
        // {
        //   title: 'resources',
        //   routeNames: [],
        //   to: ''
        // }
      ]
    })

    const resourceItems = computed(() => {
      return [
        // {
        //   title: 'v3_farm',
        //   openNew: !isEverpayFarm,
        //   to: everpayFarmHost
        // },
        // {
        //   title: 'explorer',
        //   openNew: true,
        //   to: everpayScanHost
        // },
        // {
        //   title: 'light_paper',
        //   openNew: true,
        //   to: locale.value.includes('zh')
        //     ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
        //     : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
        // },
        // {
        //   title: 'sdk',
        //   openNew: true,
        //   to: `https://docs.everpay.io/${locale.value.includes('zh') ? '' : 'en/'}docs/sdk/everpay-js/intro`
        // }
      ]
    })

    const mobileNavItems = computed(() => {
      return navItems.value.filter(item => item.title !== 'resources').concat(resourceItems.value as any)
    })

    const languages = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'zh',
        label: '简体中文'
      }
    ]
    if (isEverpayApp || isEverpayOfficialWebsite) {
      languages.push({
        value: 'es',
        label: 'Español'
      },
      {
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-TW',
        label: '繁體中文'
      })
    }
    const scrollPickerOptions = [languages]
    const scrollPickerValues = ref([locale.value])

    watch(locale, () => {
      scrollPickerValues.value = [locale.value]
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = document.documentElement.offsetWidth
        if (windowWidth.value >= 640) {
          mobileNavModalOpened.value = false
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      window.addEventListener('scroll', () => {
        if (windowWidth.value >= 640) {
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      document.addEventListener('click', (e) => {
        if (!isLangWrapperTarget(e.target as any)) {
          languagesVisible.value = false
        }
        if (!isResourcesWrapperTarget(e.target as any)) {
          resourcesVisible.value = false
        }
      })
    })

    const changeLocale = (lang: string) => {
      window.localStorage.setItem(savedI18nStorageKey, lang)
      locale.value = lang
      context.emit('handleLocaleChange', lang)
      mobileNavModalOpened.value = false
      languagesVisible.value = false
    }

    const confirmLocale = () => {
      locale.value = scrollPickerValues.value[0]
      context.emit('handleLocaleChange', locale.value)
      languagesVisible.value = false
      mobileNavModalOpened.value = false
    }

    return {
      t,
      isProd,
      isEverpayApp,
      isMobile,
      everpayOfficialWebsiteHost,
      locale,
      navItems,
      languagesVisible,
      languages,
      changeLocale,
      confirmLocale,
      mobileNavModalOpened,
      scrollPickerValues,
      scrollPickerOptions,
      resourcesVisible,
      resourceItems,
      mobileNavItems,
      navbarBtnBorderClass,
      navbarTextColorClass,
      navbarTextColorHoverClass,
      navbarBtnBackClass,
      routeName
    }
  }
})

