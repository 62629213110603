import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pt-16 pb-7 px-5 lg:pb-10 lg:pt-20 xl:px-0 bg-everPurple4 text-everGray4" }
const _hoisted_2 = { class: "flex-row items-start justify-between pb-16 ml-auto mr-auto w-full lg:pb-28 lg:flex xl:w-1200px" }
const _hoisted_3 = { class: "mb-10" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "slogan text-sm text-everGray4" }
const _hoisted_6 = { class: "flex-row items-start justify-start sm:flex lg:justify-end lg:ml-6" }
const _hoisted_7 = { class: "text-white text-sm font-bold mb-4" }
const _hoisted_8 = ["target", "href"]
const _hoisted_9 = { class: "clear-both flex-row items-center justify-between ml-auto mr-auto w-full sm:flex xl:w-1200px" }
const _hoisted_10 = { class: "text-sm" }
const _hoisted_11 = { class: "flex flex-row items-center justify-start sm:justify-end" }
const _hoisted_12 = ["href", "target"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "w-28 mb-3.5",
          src: require('../images/everpay-white.png'),
          alt: "everpay"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('slogan')), 1)
      ]),
      _createElementVNode("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerEntries, (entryItem, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["float-left mb-14 w-1/2 sm:float-none sm:mb-0 sm:w-56 lg:w-60 last:w-auto", index === 2 ? 'clear-both' : ''])
          }, [
            _createElementVNode("h4", _hoisted_7, _toDisplayString(entryItem.title), 1),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entryItem.items, (linkItem, linkIndex) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: linkIndex,
                  class: "mb-4 last:mb-0"
                }, [
                  (linkItem.to)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: linkItem.to
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(linkItem.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "text-base transition-colors hover:text-everGray2",
                        target: !_ctx.isMobile && linkItem.link ? '_blank' : '',
                        href: linkItem.link ? linkItem.link : 'javascript:void(0);'
                      }, _toDisplayString(linkItem.title), 9, _hoisted_8))
                ]))
              }), 128))
            ])
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, " © " + _toDisplayString(_ctx.year) + " everPay. All rights reserved. ", 1),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (social) => {
          return (_openBlock(), _createElementBlock("a", {
            key: social.link,
            href: social.link,
            target: !_ctx.isMobile ? '_blank' : '',
            class: "mr-5 mt-2 sm:mr-0 sm:ml-6 sm:mt-0"
          }, [
            _createElementVNode("img", {
              class: "block w-5 h-5 sm:w-6 sm:h-6",
              src: require(`../images/${social.name}.svg`),
              alt: "social"
            }, null, 8, _hoisted_13)
          ], 8, _hoisted_12))
        }), 128))
      ])
    ])
  ]))
}