const messages = {
  app: 'App',
  wallet: '钱包',
  bridge: '跨链',
  sdk: 'SDK',
  light_paper: '白皮书',
  NFT_auction: 'NFT拍卖',
  resources: '资源',
  explorer: '浏览器',
  lang: '简体中文',
  done: '完成',
  launch_app: '立即体验',
  slogan: '实时代币协议',
  account: 'everPay 账户',
  use_app_connect: '使用 {connectAppName} 连接',
  products: '产品',
  develop: '开发',
  v3_farm: '流动性挖矿',
  documentation: '文档',
  support: '用户支持',
  help_center: '帮助中心',
  privacy_policy: '隐私条款',
  faqs: '常见问题',
  about: '关于',
  time: '时间',
  from: '来自',
  to: '转到',
  coming_soon: '敬请期待',
  transfer: '转账',
  transfer_on_everpay: 'everPay 转账',
  deposit: '充值',
  depositing: '充值中',
  withdraw: '提现',
  withdrawing: '提现中',
  swap: '兑换',
  transactions: '交易记录',
  successful: '成功',
  failed: '失败',
  internal_tx: '内部交易',
  swap_records: '兑换记录',
  contacts: '联系人',
  connect: '连接钱包',
  disconnect: '取消连接',
  connect_wallet: '连接钱包',
  connect_wallet_info: '连接Ethereum、Arweave或Moonbeam钱包以存入和管理资金。',
  asset: '资产',
  assets: '资产',
  assets_connect_msg: '连接钱包可以进行充值和管理资产。',
  receiver: '收款账户',
  amount: '数量',
  balance: '余额',
  fee: '手续费',
  copy: '复制',
  edit: '编辑',
  delete: '删除',
  add: '添加',
  modify: '修改',
  filter: '筛选',
  all: '所有',
  all_currencies: '所有币种',
  transaction_type: '交易类型',
  balance_mark: '余额：',
  immediately_add: '立即添加',
  network: '网络',
  choose_network: '选择网络',
  choose_wallet: '选择钱包',
  choose_token: '请选择 Token',
  choose_contact: '请选择联系人',
  spread_percent: '价差',
  insufficient: '余额不足',
  click_deposit: '请充值',
  click_details: '点击查看',
  complete: '完成',
  everpay_balance: 'everPay 余额',
  chain_balance: '{blockchain} 链上余额',
  transfer_amount: '转账金额',
  receive_account: '收款账户',
  send: '发送',
  receive: '接收',
  chain_type: '链类型',
  support_chain_type: '支持链类型',
  symbol: '资产名称',
  max_amount: '(所有)',
  system_upgrading: '系统升级中，请等待系统升级完成',
  myaccount: '我的账号',
  no_data: '暂无数据',
  current_account_address: '当前 everPay 账户地址',
  contact_exists: '联系人地址已存在',
  no_contacts: '尚未添加任何联系人',
  edit_contact: '修改联系人',
  add_contact: '添加联系人',
  delete_contact: '删除联系人',
  contact_name: '联系人名称',
  contact_account: '联系人地址',
  new_address_detected: '检测到新地址，添加联系人',
  edit_success: '修改成功',
  add_success: '添加成功',
  delete_success: '删除成功',
  sign_message: '签名信息',
  confirm: '确定',
  cancel: '取消',
  total_cost: '实际花费',
  swap_out_amount: '转出数量',
  swap_in_amount: '转入数量',
  rate: '汇率',
  swap_success: '兑换成功',
  connect_success: '连接成功',
  disconnect_success: '取消连接成功',
  view_swap_records: '查看兑换记录',
  swap_failed: '兑换失败',
  swap_refund: '已退还',
  slippage: '滑点',
  swap_slippage: '设置滑点',
  not_found: '错误 404',
  not_found_desc: '当前页面不存在',
  back_to_home: '返回首页',
  transfer_receipt_support: '支持 ethereum, arweave 账户',
  withdraw_receipt_chaintype_support: '{chainType} 钱包地址',
  quick_withdraw_mode: '快速模式',
  quick_withdraw_mode_with_time: '快速模式（预估 {time} 秒）',
  refunded: '已退款',
  quick_withdraw_mm: '快速提现做市商：',

  quick_withdraw: '快速提现',
  quick_withdraw_data_not_inited: '数据未初始化',
  quick_withdraw_refund: '快速提现退款',
  quick_withdraw_long_time: '快速提现耗时较久，暂不支持开启',
  expected_receive: '预计到账',
  actual_fee: '手续费',
  actual_receive: '实际到账',
  insufficient_quick_withdrawal: '快速提现额度不足',
  token_insufficient_quick_withdrawal: '{token} 快速提现额度不足',
  quick_withdraw_info_url: 'https://everpay.zendesk.com/hc/zh-cn/articles/4404465610137-everPay-%E5%BF%AB%E9%80%9F%E6%8F%90%E7%8E%B0',

  pls_not_transfer_to_exchange: '转账在 everPay 网络进行，请勿转账至交易所或合约地址，无法找回！',
  pls_not_withdraw_to_exchange: '请勿提现至交易所或合约地址，无法找回！',
  pls_use_ethereum_wallet: '请先安装 MetaMask 或使用 以太坊钱包打开',
  pls_install_arweave_wallet: '请先安装 Arweave 钱包',
  pls_change_to_mainnet: '请切换到以太坊 mainnet 主网',
  pls_change_to_kovan: '请切换到以太坊 kovan 测试网络',
  copy_success: '复制成功',
  introduction_to_everPay: 'everPay 产品介绍',
  balance_info_url: 'https://everpay.zendesk.com/hc/zh-cn/articles/4405069888537-%E4%BB%80%E4%B9%88%E6%98%AF-everPay-%E4%BD%99%E9%A2%9D-',
  total_assets_of: '总资产折合',
  invalid_name: '无效名称',
  war_more_info: 'Wrapped AR 通过对原生 AR 进行 1:1 锁定，并且在以太坊生成了 ERC20 的 Wrapped AR 代币，Wrapped AR 和 AR 的价值完全相等',
  balance_more_info: '您的资产存储在 everPay 协议上。everPay 是独立于其他区块链的协议，可以认为 everPay 是一个 Layer2 或侧链',
  quick_mode_more_info: 'everPay 团队联系了外部做市商团队，通过 everPay 转账实现了快速提现，达到节省用户提现时间和费用的目的',
  support_token_search: '支持 Token 名称查询',

  transfer_page: {
    success: '转账成功',
    view_transfer_txs: '查看转账记录',
    nofunds: '当前资产没有余额，',
    intro: '可信跨链是如何工作的？',
    input_amount: '请输入转账数量',
    transfer_detail: '转账信息',
    click_url: 'https://news.ever.vision/everpay-a-trusted-cross-chain-payment-protocol-eba4a0af7d66'
  },
  deposit_page: {
    pending_balance: '充值中',
    valid_balance: '可充值：',
    balance: '当前余额',
    step1: '等待充值交易确认',
    step2: '等待{waitBlock}个区块确认',
    input_amount: '请输入充值数量',
    war_info_1: '通过以太坊钱包连接时，选择 AR，将使用',
    war_info_2: '充值获得 AR。',
    deposit_info: '将Arweave,Ethereum,Moonbeam 等链上资产存入everPay。'
  },
  withdraw_page: {
    withdraw_amount: '提现金额',
    withdraw_address: '提现地址',
    withdraw_network: '提现网络',
    withdraw_to: '提现到',
    withdraw_time: '普通提现跨链确认时间较长，需要 {time} 分钟。',
    withdraw_fee_notice: '提现手续费不足',
    valid_balance: '余额：',
    quick_withdraw_limit: '快速提现余额：',
    step1: '等待AR区块确认',
    step2: '等待提现交易确认',
    quick_step1: '做市商已到账',
    quick_step2: '等待快速提现交易确认',
    input_amount: '请输入提现数量',
    withdraw_detail: '提现详情',
    war_info: '提现 AR 至以太坊区块链，可获得',
    war_info_url: 'https://everpay.zendesk.com/hc/zh-cn/articles/4404199743257-Wrapped-AR',
    withdraw_info: '将 everPay 上的资产提现到目标链，如 Ethereum 等。'
  },
  transactions_page: {
    done: '交易完成',
    in_progress: '交易进行中',
    ar_record: 'AR区块记录',
    deposit_record: '充值记录',
    withdraw_record: '提现记录',
    refund_record: '退款记录',
    quick_withdraw_record: '快速提现记录'
  },
  account_page: {
    account_id: '账户',
    account_detail: '账户信息',
    everpay_asset: 'everPay 资产',
    chain_asset: '链上资产'
  },
  swap_page: {
    swap_detail: '兑换详情',
    submitted: '提交成功',
    ws_connecting: '系统正在连接，请刷新或稍后重试。'
  },
  genqr_page: {
    qr_code_tips: '请选择收款资产，生成二维码后，保存收款码即可轻松收款。',
    generate_qr_code: '生成收款码',
    receipt_code: '收款码',
    collections: '{symbol} 收款',
    scan_qr_code: '扫描二维码，转入 {symbol}',
    save_the_screenshot: '请截图保存',
    wallet_account: '收款账户',
    fill_account: '连接钱包后将自动填充收款账户'
  },
  error: {
    error_zero: '金额需要大于0',
    error_less_fee: '金额需要大于手续费',
    error_receiver: '收款人地址有误',
    error_receiver_chain_type: '错误的 {chainType} 地址',
    error_transfer: '转账失败',
    error_withdraw: '提现失败',
    error_deny: '交易被用户拒绝',
    deny_connect: '用户取消连接',
    quick_withdraw_chain_type: '不支持快速提现至 {chainType} 区块链',

    less_then_zero: '转账金额需要大于0',
    invalid_address: '收款人地址有误',

    err_eth_tx_minted: '交易已执行',
    err_nil_amount: '金额为空',
    err_zero_amount: '金额为零',
    err_negative_amount: '金额需要大于0',
    err_larger_amount: '金额过大',
    err_larger_data: '数据过大',
    err_insufficient_balance: '余额不足',
    err_self_transfer: '请不要给自己转账',
    err_invalid_amount: '金额无效',
    err_invalid_fee: '费用无效',
    err_invalid_tx_type: '交易类型无效',
    err_invalid_owner: 'owner无效',
    err_invalid_address: '地址无效',
    err_invalid_nonce: 'nonce无效',
    err_invalid_signature: '签名无效',
    err_invalid_mint_tx: 'mint无效',
    err_invalid_locker: 'locker无效',
    err_invalid_tx_version: '交易版本无效',
    err_token_not_found: '不支持的代币',
    err_invalid_chainid: 'chainid无效',
    err_tx_minted: '交易已打包',
    err_negative_fee: '手续费错误',
    err_invalid_action: 'action无效',
    err_invalid_eth_tx: 'ETH Tx 错误',
    err_invalid_erc20_tx: 'erc20 Tx 错误',
    err_invalid_eth_address: 'ETH 地址无效',
    err_invalid_account_type: '账户类型无效',
    err_nonce_too_low: 'nonce无效',
    err_invalid_transaction: '交易无效',
    err_limit_exceeded: '请求过于频繁，请稍后再试',
    err_node_syncing: '等待系统同步完成',
    err_system_upgrade: '系统升级中，请等待系统升级完成',

    // express
    err_no_enough_fee: '快速提现手续费不足',
    err_wallet_no_enough_fund: '做市商快速提现额度不足',
    err_unsupport_withdraw_chain_type: '不支持快速提现至 {chainType} 区块链',
    err_unsupport_token: '不支持 {token} 快速提现',

    // swap
    err_no_tx_data: 'everPay 交易data 为空',
    err_invalid_tx_data: 'everPay 交易data 无效',
    err_invalid_app_id: 'app id 无效',
    err_no_tx_type: 'everPay 交易 type 为空',
    err_miss_swap_tx_data: '缺乏兑换信息',
    err_no_pool: '不支持的资金池',
    err_invalid_token: '不支持的代币',
    err_token_out_amount_too_small: '价格变化，不满足此数量的兑换，请重试',
    error_insufficient_fund: '此交易流动性不足',
    err_invalid_order: '错误的订单数据',
    err_invalid_amout: '错误的订单数量',
    err_no_order: '未找到订单',
    err_swap_race_condition: '兑换处于竞争状态',
    err_everpay_tx: '提交兑换交易失败',
    err_miss_parameter: '缺少参数',
    err_unkown: '未知的兑换错误',
    err_insufficient_fund: '做市商资金池余额不足，请减少兑换数量',
    error_invalid_order: '错误的订单',
    network_error: '网络繁忙，请稍后再试',
    err_invalid_swap_user: '连接地址与LP 节点地址相同时，交易可能会失败。请切换账户。',
    // web3
    CALL_EXCEPTION: '合约调用失败',
    INSUFFICIENT_FUNDS: '矿工费不足',
    NETWORK_ERROR: '以太坊网络错误',
    NONCE_EXPIRED: 'nonce 错误',
    REPLACEMENT_UNDERPRICED: '替换交易矿工费不足',
    UNPREDICTABLE_GAS_LIMIT: 'gasLimit 不足',

    INVALID_ACCOUNT_TYPE: '不支持的账户类型'
  },

  // new swap
  err_not_found_path: 'path 缺失',
  err_not_found_salt: 'salt 缺失',
  err_not_found_lp: 'lp 缺失',
  err_no_authorization: '未授权',
  err_duplicate_registration: '重复注册',
  err_invalid_msg: '无效的 message',
  err_invalid_token: '无效的 token',
  err_invalid_order: '无效的 order',
  err_invalid_address: '无效的 address',
  err_invalid_signature: '无效的 signature',
  err_invalid_paths_or_bundle: '无效的 paths 或 bundle',
  err_invalid_pool: '无效的 pool',
  err_no_pool: '没有 pool',
  err_no_lp: '没有 LP',
  err_no_path: '没有 path',
  err_invalid_amount: '无效的 amount',
  err_out_of_range: '超出范围',
  err_invalid_number: '无效的 number',
  err_no_implement: '没有执行',
  err_invalid_path: '无效的 path',
  err_invalid_swapouts: '无效的调换',
  err_invalid_pool_paths: '无效的 pool paths',
  err_invalid_price: '无效的 price',
  err_invalid_liquidity: '无效的 liquidity',
  err_invalid_price_direction: '无效的 price direction',
  err_invalid_fee: '无效的 fee',

  PST_WITHDARW_TO_ARWEAVE_MUST_BE_INTEGER: 'PST Token 提现至 Arweave 必须为整数',
  // NFT
  nft_error: {
    err_invalid_appId: 'appId无效',
    err_invalid_action: 'action无效',
    err_bid_self: '您已经拥有当前NFT,无需再拍',
    err_invalid_referredBy: '推荐者地址无效',
    err_invalid_nftRecipient: 'NFT收藏地址无效',
    err_invalid_tx: '交易无效',
    err_invalid_nftWallet: '艺术家钱包无效',
    err_invalid_amount: '出价金额无效',
    err_invalid_token: 'token 无效',
    err_invalid_path: 'path 无效',
    err_not_found_auctionId: '缺少NFT拍卖批次',
    err_not_found_nftId: '缺少 NFT ID',
    err_not_during_event: '拍卖时间不符',
    err_not_recaptcha: '缺少验证',
    err_more_than_bidLimit: '不可同时竞拍超过{bidLimit}个NFT',
    err_bid_too_low: '出价不得低于总价格的百分之五, 请重新出价',
    err_bid_too_high: '出价已超出总价格百分之二十, 请减少金额拍卖',
    err_more_than_ipLimit: '当前IP被限制,稍后重试',
    err_recaptcha_failed: '验证失败，请重试!',
    err_add_price: '很抱歉，价格已失效，当前价格为{amount}，请重新加价。'
  },
  artist_info: {
    artist_information: '艺术家信息',
    introduction: '简介',
    past_work: '过去的作品',
    more_about: '更多信息',
    inner_motion_important: '数字艺术品牌「异星艺想」，是为满足艺术家意志能够自由表达的需要而诞生的。',
    inner_motion_general_info: '其所有艺术作品的创作灵感来源于将生活、色彩、声音与神圣几何概念之间的结合。 几何存在于我们周围的一切事物中，从最小的物体或生物到最大的天文力量，在形状、比例和对称性之间总是存在着平衡，运用几何图形的各种结构来为艺术品寻找内在的平衡即是「异星艺想」的创作理念。「异星艺想」在创办以来，与来自中国和欧洲各地的 DJ 以及音乐节合作，通过社交媒体、艺术展览和音乐品牌来分享其艺术作品。“享受生活，分享与关爱” 是「异星艺想」的创作哲学。',
    inner_motion_general_info2: '此次作品是系列动画物体，其灵感来自人类历史上最古老的艺术形式之一：曼荼罗。它是一种神圣的指引工具，被用于帮助修行者开启从外部到内核的精神之旅。该系列采用了这个古老的传承，表达了几何形状和精确运动之间的真正平衡。',
    micro_plants_important: 'Noelia Puig（1995年，西班牙）是一名数字内容创作者，使用笔名 nueps 进行艺术创作。',
    micro_plants_general_info: '对荒谬、如梦和超现实艺术作品的兴趣，使她走上了创作的道路。nueps 擅长使用荧光色和数字处理把自然世界的事物转化为高度抽象的版本。她创造了一个神奇的宇宙，从科幻的生物到最日常、平凡物体的重塑。',
    ar_drive_important: '这次拍卖是由 everPay 与 ArDrive 联合推出。ArDrive 是建立在 Arweave 区块链上的去中心化永久存储应用。得益于 Arweave 所提供的足以改变行业规则的技术， 让任何类型的数据可以保存至少 200 年，甚至更久。一旦你最重要的文件通过 ArDrive 上传，它们将成为你永久的记忆。 来自 ArDrive 社区的 =XaviZedd  为此次拍卖创作了龙龟 NFTs。',
    conflux_important: 'Conflux 是一条 Layer1 公链，跨越国界和协议连接去中心化的经济，采用 PoW/PoS 混合运行的机制。它采用独特的 "树状图 "共识机制--动态区块结构，在不牺牲去中心化和安全性的前提下，促进 3000-6000 TPS 的处理。作为中国唯一符合监管的，无需许可的公链，Conflux 为在亚洲本地或希望进入亚洲市场的项目提供支持。Conflux 的目标是连接去中心化经济体，以加强全球 DeFi 生态系统发展。',
    akord_important: 'Akord 邀请了艺术家 Serge Seidlitz 为此次 NFT 拍卖活动创作了 Crypto Slang Gang 系列的 9 个NFTs，以庆祝 Akord 协议 smartweave 新版的推出。Crypto Slang Gang 所包含的标志性人物是由 Akord 所铸造的第一个在 Arweave 上的原子 NFTs。 Serge Seidlitz 是知名经纪人机构 B&A 旗下艺术家，在他 20 年的职业生涯中，他曾为《纽约时报》、《华盛顿邮报》、《卫报》、英国航空公司、苹果、索尼、三星、MTV、可口可乐、Uber 和 KFC 提供设计。',
    arks_important: '经过数百代人的寻觅，Arks 是最终首批 43 艘到达 Yowunas Maias 定居并开始生活的方舟。该系列 NFT 将发行在以太坊上并在 Arweave 上进行托管。 Arks 系列还是 decent.land 早期用户的通行证 -- web3 dapps 的社会和身份协议。Arks 持有者将被列入白名单，可用于未来的 decent.land 、代币和更多使用场景。除了在协议中的使用场景， decent.land 传说的故事前奏将通过 Arks 来展开。每个 Ark 的元数据都包含了创作者兼艺术总监 Lee Tyrrell 的丰富背景故事，其特征与叙述中的事件相互呼应。Jrdsctt 是 Arks 背后的视觉艺术家，他是一位多产的平面设计师和摄影师，以突出的美术 1/1 而闻名，并利用代码操纵艺术进行创新。',
    birdsong: {
      href_msg: '收藏品',
      important_msg1: '《鸟鸣集》是以一系列濒危鸟类的歌声为灵感制作的专辑，旨在提高人们保护鸟类的意识以及筹集资金。在以前的专辑取得巨大成功后，本次在 everPay 上推出了新的合辑包含九首鸟类歌曲。 所有的歌曲将被永远储存在 Arweave上。',
      important_msg2: '这个项目是由非营利组织 Shika Shika 和音乐艺术家 El Búho 进行策划。全部收入将用于保护濒危鸟类。',
      important_msg3: 'PIANITY',
      important_msg4: 'Pianity 旨在通过音乐支持有意义的的行动。请看以前所有售罄的',
      important_msg5: '本次音乐辑都发行在 Pianity 平台之上，Pianity 是一个音乐 NFT 平台，音乐家和社区成员聚集在此，创造、收集和交易限量版的歌曲。Pianity 开创了一种全新的模式：让所有人可免费收听音乐，并将智能合约与音乐文件永久存储在 Arweave 上，这使艺术家和他的听众之间有了更深的联系。',
      important_msg6: 'NFT 将是 PermaSwap 流动性提供的入场券。此外，赢取 NFT 的用户将被授予 Pianity Discord的最高 DAO 等级，以参与 Pianity 的治理。',
      important_msg7: '',
      important_msg8: '',
      important_msg9: ''
    }
  },
  auction: {
    emilie: '乐观开朗的Emilie天生对生活充满了期待，也对万物充满了向往。头顶的项圈是她童真的象征，在家族里是大家快乐的小天使。',
    vincent: '酷炫而又不失稳重的眼镜是Vincent的特点，遇事沉着冷静总是一副知识分子的样子，能够洞察事物的本质，但过于古板总是受到家族的嫌弃。',
    enola: 'Enola总是很害羞，但耳朵上的耳环又透露出她内心的叛逆。在家族中算是大家的乖乖女，害羞腼腆，不爱说话，喜欢抱着书学习。但私下跟好友在一起时却会放声大笑玩闹。',
    ronney: 'Ronney天生放荡不羁，喜欢捉弄别人，总是对着别人做鬼脸，但Ronney的内心其实是个大哥哥，喜欢帮助别人，有什么问题总会带以微笑替别人出头。',
    peggy: 'Peggy是家族里最小的一位，可可爱爱，带着小奶瓶四处跟别人撒娇。家族的人都对她宠爱有加，可是Ronney在家中却总把Peggy弄哭，惹得大家都不希望Ronney靠近他。但在外面Ronney也会表现出大哥哥的架势，疼爱和保护Peggy。',
    aron: 'Aron天生是个运动健将，永远戴着他的棒球帽，生怕别人不知道他是棒球好手，Aron同样也是个乐天派。',
    yvan: 'Yvan作为家族里的长者，受到所有人的尊敬，同时Yvan非常疼爱所有家族成员，圣诞老人的造型就是他对成员最好的爱，在他的红袋子里，总带着很多惊喜，Peggy的哭闹只有Yvan能安抚的好。',
    george: 'George是个长不大的孩子，虽然已经成年，但总是有着孩童般的想法和行为，更像是个梦想家，活在自己的世界里，永远快乐。但在家族里，大家还是对他很照顾，也不希望破坏了他那孩童般的梦。',
    oscar: '作为家族中第二大的长辈，Oscar在家族里的地位不亚于Yvan, 他有着更成熟的外表和内在，总是一副深沉的样子，特别是他不离嘴的烟斗，每当他在想事情的时候，总能够看见一缕缕烟雾。家族的小伙伴都很怕他，也只有他能管理好这个大家庭。'
  },
  pierre_nft: {
    title: '由 Pierre x everPay 联合呈现：新石器时代的现代陶艺系列',
    inception: '红色的陶器。在整个新石器时代文化中，"琴形 "人物无处不在。现在，他们又突然出现在了元宇宙。',
    dictators_place: '镶嵌着高岭土的黑色陶器。垃圾桶，但不是垃圾的艺术。它是我们这个时代潘多拉的魔盒。',
    chartzilla: '红色的陶器。让我们一起赞美女神。',
    praying_mantis: '黑色的陶器。有时爱是残酷的。这是对 "思想者 "和 "坐着的女人 “的重新诠释，来自公元前5000年左右制作的拟人化雕像。从表面看本质。',
    greed: '红色的陶器。无论在什么年代，无论你从什么角度去看，贪婪都是一样的！',
    memory: '红色陶器。简单的泥塑，结合了创作者珍藏的两种表达媒介：陶器代表物理的永恒生命，Arweave 代表数字的永恒生命。',
    pepe_on_fur: '白色的陶器。这是件有趣的作品。Burt Reynold的标志性画报和=Vince_Van_Dough毛皮人的玩笑。',
    facing_the_past: '白色的陶器。偶尔，古老的神灵也会让人感到恐惧。谁知道 Munch 看到了什么…',
    keks_Mother: '白色的陶器。就算是古埃及的神也有他们的祖先，那是人类无法想象的古老记忆。'
  },
  chengliang_nft: {
    title: '异星乐园与奇异宇宙人',
    tyger: 'Tyger 是一只来自 "Wonderland and the Funny Fellows" 系列的坏脾气的外星老虎，它正在秀它尖尖的牙齿。',
    miserable: 'Miserable 是一个来自 "Wonderland and the Funny Fellows" 系列的非常不幸的滑稽家伙，它正试图逃离一场灾难性的火灾。',
    spreader: 'Super Spreader 是一个来自 "Wonderland and the Funny Fellows" 系列的有趣家伙，它无意中成为一种致命传染性病毒的超级传播者。',
    doctor: '一个拿着疫苗注射器的异星医生。这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。',
    maniac: '疯狂博士总是乘着炸弹飞翔。致敬奇爱博士。这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。',
    loser: '一个放弃任何努力的奇异宇宙人。这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。',
    musician: '奇异宇宙人乐手正在弹奏一首经典歌曲。这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。',
    mastermind: 'AR大师终于得到了宝贵的Arweave，他已经做好了征服银河的准备。这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。这是该系列与everPay以及AR网络合作的特别版。',
    masker: '从来没人见过那副面具背后的真面目！这张宇宙人肖像来自于Tiger正在持续创作中的《异星乐园与奇异宇宙人》系列。'
  },
  breaking_panda_nft: {
    title: 'Hi-five! everPay 与破次元熊猫！',
    coronation: 'Breaking Panda 终于拥有了他的王冠，准备去迎接来自病毒的挑战。让我们期待不一样的勇气和好运吧！',
    quarantine: 'Breaking Panda 在这个特殊的假期，居家也要每天拥有精致的装扮，他还举办一场有意思的线上美食分享会！',
    breakfast: '与Breaking Panda 在特别的早晨，来一通美好的电话煲吧，他有个好梦跟你分享哦，你猜他梦见了什么？',
    summer: '粉红色的世界里，来 look 下我们 Breaking Panda 新造型，最重要他拥有了粉色西瓜和美味的竹子，这可真是一个惊喜呀！',
    ar_panda: '在AR的陪伴下每天都是活力满满的一天，让我们跟着breaking panda一起听着音乐跳起舞吧!',
    storm: 'Breaking Panda是多巴胺生成器，有breaking panda的每一天心情里都有五彩斑斓的那束光。',
    son: '当Breaking Panda遇到人类之子会有什么神奇魔法呢?',
    lfg: 'Breaking Panda带着最喜欢的竹子能量开始星际旅行啦，快来跟随魔法的脚步吧!',
    creation: '当手柄可以成为创作日常的工具，我们是不是每天可以生活在自己粉饰的世界了?'
  },
  inner_motion_nft: {
    title: '内部动力',
    arweave_series: '「异星艺想」用一系列盛开的曼荼罗来迎接春季。这个作品是「内在动力」系列的一部分。Arweave 特别版。',
    beacon_series: '这幅动画作品是外星智慧的星际灯塔。',
    clock_series: '曼荼罗时钟将带你进入第四维度 —— 时间。从过去，到现在，再到未来，万事万物不可逆转地运行。'
  },
  micro_plants: {
    title: '奇幻盆栽',
    micro_plants_1: '台灯模样的听诊器铃铃铃地响',
    micro_plants_2: '水母与蜘蛛奏响友谊的号角',
    micro_plants_3: '小心被奖杯吃掉哦',
    micro_plants_4: '多层眼皮的生菜睁开了眼睛',
    micro_plants_5: 'Arweave、心、五彩斑斓',
    micro_plants_6: '海星畅游太阳迷宫',
    micro_plants_7: '请欣赏鼻涕虫盆栽',
    micro_plants_8: '不安分的章鱼爪子',
    micro_plants_9: '蜂蜜香气的蝴蝶结裤子'
  },
  dragon_turtles: {
    title: '龙龟',
    bixi: 'Bixi，是龙龟中最年长和最具有智慧的一位，指引着前行的方向并给予力量。',
    zhang: 'Zhang 是一只喜欢冒险的龙龟，对口香糖情有独钟。',
    zuo: 'Zuo 拥有龙龟的皇家血统，会给人们带来好运。',
    lulu: 'Lu Lu 的跳投技术非常厉害，并且可以用他的刀在半空中切蔬菜。',
    xibi: 'Xibi 会用手上的斧头弹奏音乐，他对重金属也略知一二。',
    yuan: 'Yuan 会用手上那把激光剑保护 Blockweave 的同时，测测自己的运气。',
    xuanwu: 'Xuanwu 用他的地狱火球点燃了人们心灵的火花。他的持有者将在下一阶段，收到由 ArDrive 空投的一枚专门定制的 Permapunk（NFT）。',
    feifei: 'Fei Fei 总是那么恬静，给他的朋友们带去诸多祝福。',
    ao: 'Ao 是博学的龙龟。在业余时间，他照料着一池锦鲤。'
  },
  con_fi: {
    title: '怀揣梦想，踏上天涯路。每一只烤仔代表着人生的一种生活态度。乐观向上是烤仔想要展示给大家的人生观，希望在人生的旅途之中，烤仔的每一面都可以伴你同行。',
    teletubbies: '怀有一颗赤子之心',
    superhero: '揣着智慧与勇敢',
    lonely_warrior: '梦里也许会哭泣，但是希望就在明天',
    great_love: '想对全世界说爱你',
    pirate_king: '踏上坚毅的航海旅途',
    doctor_strange: '上天入地，探索时空奥秘',
    praise: '天使的面庞夸赞他人，希望被世界温柔以待',
    cutie: '涯际无边春色好，天高海阔任君裁',
    curious: '路途遥远，但永远充满好奇'
  },
  crypto_gang: {
    title: 'Crypto Slang Gang 是 Serge 对 crypto 和 web3 的另一次探索，每个角色都代表了行业内的流行语或俚语。除 NFT 外，每件作品还将被制作成 50x50cm 的美术版画，由 Serge 亲笔签名，在全球范围内免费寄送。',
    the_whale: 'Twitter 上的每个人都密切关注着巨鲸的交易。无论他走到哪里，吞噬加密货币就像捕获浮游生物一样简单。',
    the_bear: '这是只悲观主义熊。当价格下降时，他总爱说 "我早就告诉过你吧"。这时最好不要理会他，努力工作就好。',
    the_bull: '当活力牛在城里的时候就是派对时间。每个人都喜欢他，他不管做什么，总是能充满活力，但他也是出了名的容易崩溃。',
    rekt_guy: '破坏王被毁了-- 悲观熊夺走了他的藏品。加密货币市场也有小幅下跌。活力牛在哪里呀？',
    paper_diamond: '感谢那些在底部割肉的纸牌手，钻石手总能从他那里购买到代币。',
    high_priest: '月神大祭司总是知道哪个项目接下来会翻 100 倍。在下一次交易之前，请和他成为好朋友。',
    gm: '在 Twitter 和 Discord 里，他很乐意在一天中的任何时候用简短的早安问候你。GM，说得够多了吧！',
    fud: '你有没有被 FUD 感染过？恐惧! 不确定性! 怀疑! 这在加密货币中随处可见。严重时，更使人瘫痪，所以大家要小心呀。',
    degen_guy: '你这个失去理智的投资者，他刚刚把最后的钱投入到高点中。他就是那个在所有社交媒体上大喊 To da Moon 的人。'
  },
  arks_decent_land: {
    title: '经过数百代人的寻觅，Arks 是最终首批 43 艘到达 Yowunas Maias 定居并开始生活的方舟。该系列 NFT 将发行在以太坊上并在 Arweave 上进行托管。Arks 系列还是 decent.land 早期用户的通行证 -- web3 dapps 的社会和身份协议。Arks 持有者将被列入白名单，可用于未来的 decent.land 、代币和更多使用场景。',
    the_cryochambers: '人们大多时候都生活在死亡的阴影之下。大家都害怕死亡，谁不是呢？这是自然的规律。但对于有些人来说，现在没空考虑这些，有太多的工作等着他们去完成。 对抗时间的命运向来都是统治阶级所热衷的。君主因为得病，要求住进了冷疗室中，然后等待登陆的时刻，“看！是星球，阿嗨！” 两个政党每年交换执掌舰队，而反对党的公务员们在休息的时间里无所事事。 一些坐办公室的工作人员很聪明，他们善于储蓄，并且能够充分地将微薄的收入利用好。而对于那些高收入的人来说，他们的父母、宠物或自己能够轻易地住进冷疗室中。然而，这个过程也是十分折磨的。许多人无法原谅自己的孩子让他们去接受这种病态的折磨。但从另一个方面来说，又非常高效；生物反馈问题的报告在每一代都在减少。 这个方舟上工作人员很友好，但不会自己溜进任何房间。我们都为他们的努力鼓掌，冷冻的溶液一直在改进。只是他们已经看到了太多在解冻板上残缺的尸体。我听说牙齿会留在胶状物中，当肉质液体在轮床上蔓延时，牙齿会咔咔作响。 目前，冷冻室里住着我们的童子军队、城市居民的亲属、Whigs 的队伍和Qualat国王--我们最年长和最受尊敬的摄政者，等待着登陆的到来。',
    neighbourhood_watch: 'Gūlyu Aiak\'us 守护城市的方舟。我们有一个城市方舟区，提供舒适的居住环境，它们的味道和大小各不相同。每个方舟都有自己的特点。但 Gūlyu Aiak\'us，是城市的守护者，隐藏在他们之中，准备威力巨大的弹药轰击入侵的舰队。这艘方舟是一座运作良好的堡垒，拥有各种领土，看起来很无害。攻击者在城市群中划定了区域，尽管他们很少会出现。他们认为平民是弱者，军事反应会严重滞后。总的来说，如果不是 Aiak\'us，他们是对的。赞美我们了不起的救世主！由于 Gūlyian 的机制，许多次进攻都以胜利告终。她的炮火从船头的射出，装载着鱼雷。说实话，防御能力很低，因为她的前置武器。但 Aiak\'us 将在侵略者有机会作出反应之前就将其摧毁。如果第二波攻击接踵而至，我们的主要战斗机：中尉和他的两个 Pawn，通常已经准备好掩护他们的母舰。已经从保护性轮换中飞到了议会，协助正在进行的战斗。她是一个贫民区的主人，被迫生活在恐惧之中。她们买不起更好的东西，所以倾向于在危险贫民区。当然，她们可以得到食品、葬礼套餐和免费的心理治疗等福利。',
    immaculate_laboratory: '这是艰难的六百年，特别是对于前线的方舟来说。不定时的攻击、太阳耀斑、天体撞击以及更糟糕的情况，每隔一段时间就会出现，使我们的人数锐减。有些幸存下来的人们都已生病或被困住，被敌人烧焦更是常有的事情。一个例外是无暇实验室；一个梦想家的小屋。在这里，大家正在研究治疗被病毒侵蚀的生物船的方法。他们还与生物学家、分析学家和工程学家一起工作。然而，他们与另类推进器的关系是紧张的。他们与科学方舟的共同努力是十分关键的，他们也知道这一点。没有他们，我们就没有成功抵达的希望。我们会陷入困境或被淹没。他们最大的成就是一种可塑的液体烟雾。他们从废料中提取出这一串长长的物质，并把它送到整个舰队周围。我们用它来制造新的小艇或双桅船。实验室还通过烟雾将水变成了酒，为我们提供了食物。雾霾烤肉是我们城市地区家庭每周的传统。该物质是定量供应的，其配方是机密，会优先考虑军事库存。因此，我们竭尽所能地保护它们。他们的方舟大多是金属的，有巨大的玻璃穹顶和可编程的卷轴。在自己的房间里很安全地生活直到退休，搬到舰队的后面。',
    the_crimson_castle: '我们被遗忘已久的本土星球，据我所知，居住着许多皇室家族，彼此之间一直处于战争状态。灾难来临时，大多数人都很顽固，只有少数人加入了逃亡的队伍。绯红城堡是一个摄政家族的家，也是历史的遗迹。最年长的 Qualat 国王受到整个舰队的爱戴，但他的只会象征性的公开露面的，并使用智能全息图来传达。五代以来，他一直沉睡在冷冻室中，离他的死亡大约还有十年。他的女儿，尊贵的深红公主，在他自己沉睡后不久就失踪了。这使得 Qualat 王子，一个可恶的傻瓜，顺理成章地登上了王位。与他父亲通过全息方式的公开辩论经常被广播，许多人都会进行收看。最终，Qualat 国王在 3.4.15 版本中赶走了他的儿子，并被放逐到一艘军舰上。随之而来的叛乱被迅速、干净地清理了，尽管王子针对内阁做出了威胁。他命令 Aiak\'us 全盘销毁它们，而当她没有这样做时，这让他感到非常惊讶。那时，我们的舰队已经厌倦了他的抱怨。他最后一次被看到是在一个系外行星的轨道上螺旋上升，失去控制，岌岌可危。三个可爱的女儿是他送给舰队的临别礼物。今天，我们的全息国王已经有数不清的各种版本。绯红公主，再也没有被看到过。',
    barracks: '我们的军营形成了一个自己的城市。钟表准时运行，火车滴答作响；理发师使用刀具，回收都是是强制性的。我被告知，这里的空气是舰队中最干净的，尽管 Zaļā Yowusta 的居民声称不是这样。对我们的士兵来说，没有太多的特权，他们以牺牲来报答这种恩情。海报上宣传到，入伍， 可以保证你舒适的生活。而事实上，应该是："入伍，你保证你的生死"。每年都有一批毕业生加入 Pawn 的船员，Pawn 是我们用烟雾制作的轻型弹药模型。如果他们的制作的 Pawn 能够经受住深度侦察、攻击和碎片风暴的考验，那么团队中最优秀的人就会被调到 Aiak’us，或者被雇佣到血色警卫队。模范士兵会得到中尉的邀请，被当作军营中有抱负的士兵的终极目标。但是每条道路的尽头都是相同的悲惨结果，我们牺牲士兵的名字无限循环地滚动着。加入我们的军队被当作一种游戏出售，排行榜是一种荣耀。你能坚持多长时间？发了多少帖子？你打碎多少个外星人的头骨？这就是为什么军营维护得这么好，这让实验室感到恼火的是。他们怎么敢以咄咄逼人的气势实现它，而不为科学考虑一秒钟？',
    diseased_metropolis: 'Vetzsas Maias 曾经是一个引以为傲的首都，但现在艰难地维持这样的地位。一个拥有繁荣的媒体产业，摇摇欲坠、饱受创伤的废墟或许更加贴切。我想，当我见到麦亚斯的音乐家们时，很容易理解为什么创造力在这只古老的海豚背上绽放。我们驯服并圈养了一群在太空航行的不老生物，在它们的基础上建立了最好的城市，并以这些野兽的血液为燃料。两百多代以来，这一切都很顺利。我们为生物方舟提供食物，方舟为我们提供生活的地方，没有人有理由抱怨。只是有一些不幸的灾难，例如剧烈的胃部不适。Vetzsas 是我们对极端顺从的的首领的称呼。她陶醉于她的旅程，高兴地跳过星云、类星体、虫洞和裂缝，并被我们的希望所束缚。她十分有魅力，充满了活力，但这一切都被我们在某个遥远的星球上发现的鳄鱼所摧毁。Vetzsas 嗅到了这一点，并蹒跚着走到形成日冕光环轨道上的岩石。他们玩了一会儿，喷出了一英里宽的闪烁的星体物质的洪流。我们的首都方舟摆动了她的尾巴（以显示她在后面的附属排气管）。从那时起，在大多数有机结构的船上，我们就一直与疾病为伴。据报道，鳄鱼被我们巧妙地利用水晶的砾石胡须所包围，Aiak\'us 通过切断他的的金色头颅而杀了它。传言说，鳄鱼之盐继续为我们的烟熏烤肉调味。但是，可怜的、美丽的 Vetzsas Maias 被迫遭受一种病毒的折磨，现在这个病毒在舰队里流行开来。',
    biology: '这些傲慢的保姆对我们的出色的生物船来说，在太空的真空中几乎毫无用处，而且总是梦想着到达目的地。他们声称忙于与维护我们乘坐的生物飞船的工作。但是即使有病，舰队的有机特遣队也基本上是自主应对。相反，他们跟随 Terraforming 方舟，不断地问："我们到了吗？"野生动植物会登陆上我们的居住地。保护居住地是绿色地带城堡 Zaļā Yowusta 的全部职责。在太空中，进化是停滞不前。我们的森林完全被编入了目录，由于没有新的物种可以追踪、捕捉和记录，生物学家们已经处于疯狂的状态。他们曾经试图降落在一个没有动物的球形沼泽上。希望他们能保持对科学的兴趣并留下来，研究他们由于贪婪所带来的泛生代。尽管他们对我们的宗教主旨 "眼睛之家"很反感，但他们经常在花园的长椅上看到，对着巨大而明亮的丛林壁画祈祷。当他们终于抓住了他们世代追逐的球时，他们会怎么做呢？我预测会有一定程度的鞭长莫及；当战斗发生时，他们是第一个披上烟雾的人，躲在无暇实验室里。我怀疑他们如果在真正面对，会不会表现得非常勇敢。他们的飞船大部分都被称为自然保护区；鸟儿在穹顶的天空中飞翔，上面布满了来自人工大气循环更新的云朵。然而，生物学家们自己却很少离开他们的小型前部实验室。',
    main_engineering: '工程部左舷机舱的损失是我们防卫网薄弱的证明。住宅方舟由 Aiak\'us 保护；中尉和他的 Pawns 几乎总是围着议会。这使得我们的战舰，尽管它很坚硬并像一个守望者一样巡逻，但也因年老而衰败。将军们的直觉是正确的，我们的政治和居民地区通常是攻击者的目标。最聪明的敌人会瞄准公用设施，包括办公室和工程部。当不能以必要的速度部署时--而 "Toise "被困在其他地方--这些船处于危险之中，只有最基本的机载射手。我们自由地承认这是我们战术中的一个缺陷，但我们在有限的军队中发挥了最大的作用。五十代以前，一个狡猾的海盗向我们的舰队搭讪。他孤身一人，投机取巧，有一个失效的极地逆变器。我们很乐意高价卖给他卖给他一个替代品，但他切断了我们的联系，不管不顾地朝我们方舟的中心地带驶去。最后，他带走了整个机舱，用牵引光束将它拖过了折叠空间！我们不希望看到他再来。我们不期望再看到他，尽管 "通缉 "海报贴在公共公告栏，希望如此。在截肢后，这艘方舟被设定为拆毁。我们计划推广一艘补充性的工程船，尽管这艘受损方舟上顽固的船员拒绝放弃。他们坚称他们会用烟雾来修复它，失去一条腿是一个小的干扰。现在，被剪断的机舱的明显缺失，成为他们没有兑现的承诺的提醒。',
    diseased_cargo_hold: '事实证明，生物方舟会经常繁殖。我们相当于管理一个托儿所。当受到巨大的电击威胁时，年轻生物很容易顺从。他们的父母也很高兴把他们留给我们照看。在成年后，它们的性倾向是一个严峻的问题。我们用三层反射器来保护我们的城市的居民不受抽搐的、繁殖的行为和它兴奋的心情影响。这就是病毒感染的传播方式， Vetzsas Maias 在舰队的其他地方传播。我们知道货舱里的其他生物喜欢上了 Vetzsas，他用歌曲向她求爱，在寂静的太空中以心灵感应的方式进行表演，歌声也会进入到我们的梦里。他是个很好的人，我们允许他继续做下去，生物学家出于好奇心还鼓励这样的行为。他们认为我们必须检验这种疾病是否能性传播。一旦感染的苔藓长在船舱上，形成翅膀，他就会背弃 Vetzsas Maias，拥抱了单身生活。事后看来，我们应该给我们的方舟做绝育手术，或者至少切除船舱的输精管。事实证明，他迷人的音乐在他的潜在伙伴中是一个受欢迎的噱头。因此，一种绿宝石般的性病真菌跟随他的每一次征服。这个货舱的工作人员是安全的，但不满意，这是可以理解的。他们被区别对待，其他人在酒吧里对他们避而远之。人们相信他们已经解决了生物方舟的欲望。经过这么多年，尽管伤害已经造，鳄鱼的卵子仍然是我们最好的同伴皮肤上的青色污点。'
  },
  birdsong: {
    title: '来自中美洲、墨西哥和加勒比海的濒危鸟类的歌声',
    jamaican_blackbird: '牙买加黑鸟是该岛的特有鸟类，它的饮食与啄木鸟相似，这在黑鸟中是很独特的。它只在牙买加的科克皮地区的山林、约翰克罗和蓝山山脉出现。由于铝土矿开采对其栖息地造成的破坏，该物种成为了濒危物种。',
    cotorr_serrana_occidental: '厚嘴鹦鹉被发现于墨西哥西马德雷山脉的松树、冷杉和针叶林中，是濒危物种之一。大规模的伐木和农业造成的森林流失是其数量迅速减少的主要原因。除此非法宠物贸易也是不容忽视的威胁。',
    ferminia: '萨帕塔鹪鹩是一种十分神秘的鸟类，这是古巴岛的特有物种，栖息在萨帕塔沼泽的茂密灌丛中。该物种被列为濒危物种。湿地干涸和农业扩张使该物种的栖息地不断退化，引入的獴和老鼠则构成了生物威胁。',
    raso_lark: '该物种是极度濒危物种，野外仅存 250-999 只，作为一种地栖动物，意外引入的捕食动物对它是一种极大的威胁，特别是猫和老鼠。拉索云雀外观呈带灰色条纹，有一个大的喙和一个短的脊背。只栖息于佛得角拉索无人小岛干燥的河床和平原上。',
    rufuous_fishing_owl: '红褐渔鸮是塞拉利昂和加纳之间的上几内亚森林中特有的鸟类。 它生活在河流和泻湖的边缘，在夜间捕食鱼类、淡水蟹和两栖动物。 会发出一种柔和的、像鸽子一样的声音。 它被列为易危物种物种，该地区红树林和森林持续减少对它的生存是一种巨大的威胁。',
    sao_tome_grosbeak: '圣多美鸟是赤道群岛高原森林中特有的一种大型壶腹栗色雀鸟。 它会到森林从中，用它强有力的喙将种子压碎作为食物。受到栖息地退化和引入捕食者的威胁，它被列为极度濒危物种。',
    ibadan_malimbe: '伊巴丹马林贝是尼日利亚特有的一种稀有鸟类，它只栖息于该国西南部地区，伊巴丹市就是以它命名的。其外观为红黑相间，首次被发现于1951年，目前被列为濒危物种。由于森林砍伐导致的栖息地的流失是其面临的最大威胁。',
    timneh_parrot: '蒂姆尼鹦鹉是一种善于交际的森林栖息鸟。曾经在整个上几内亚森林中都可以看见，当现在大部分地区都已经消失它的踪影，属于濒危物种。它是欧洲、北美和中东地区最受欢迎的鸟类宠物之一，因此非法动物交易是对蒂姆尼鹦鹉最大的威胁。',
    mac_tobiano: '高大的的 Macá Tobiano 栖息在智利和阿根廷巴塔哥尼亚的湖泊和大草原上。它的声音是像有节奏的一系列上升的音符，并带有神圣的感觉。该物种的数量的自20世纪90年代以来极速下降下减少数量高达 40% 。现在已知它只在阿根廷西南部的几个特定地方出现。'
  },
  countdown: {
    day: '天',
    hours: '时',
    minutes: '分',
    seconds: '秒'
  },
  auction_start: '距离开始',
  auction_distance_end: '距离结束',
  auction_over: '已结束',
  auction_notstarted: '未开始',
  current_price: '当前价格：',
  highest_account: '最高出价账户：',
  nft_eth_collect_address: 'NFT 收藏地址：',
  nft_ar_collect_address: 'NFT 收藏地址：',
  auction_mybid: '我的出价：',
  not_yet_started: '暂未开始',
  auction_has_end: '拍卖已结束，请查看NFT信息',
  bidding: '竞拍',
  collection_nft: '收藏 Winston 大象家族系列!',
  home_title: '收藏 everPay NFT， 可参与 Permaswap 挖矿。',
  home_title2: '',
  home_title_info: '拍卖的收益将分配给用户、艺术家和 everPay NFT 持有人。快来参加竞标吧!',
  bid_now: '立即竞拍',
  connection: '请等待连接',
  fill_in_ethereum_address: '请填写NFT 以太坊 收藏地址',
  fill_in_arweave_address: '请填写NFT Arweave 收藏地址',
  fill_in_address_error: '地址格式错误，请重新填写。',
  price_restrictions: '出价不得低于当前价格的百分之五或高于百分之二十',
  current_nft_count: '您已拥有{num}个NFT, 无法再次参与竞拍',
  nft_eth_collection_tips: 'everPay NFT 将在以太坊网络发布，用户必须填写以太坊地址。',
  nft_ar_collection_tips: 'everPay NFT 将在 Arweave 网络发布，用户必须填写 Arweave 地址。',
  nft_mybid_tips: '1. 竞拍最小单位为 0.01 {symbol}; ',
  nft_mybid_tips2: '2. 竞拍价格不得低于当前价格的 5% 并且不得高于 20%。',
  already_bid_normal: '已竞拍',
  num_times: '次',
  already_bid: '已竞拍 {bidTimes} 次',
  current_account: '当前账户余额不足',
  top_up_now: '立即充值',
  pls_input_ethereum_addr: '请填写以太坊地址',
  pls_input_arweave_addr: '请填写 Arweave 地址',
  pls_input_enter_nft_addr: '请输入 NFT 收藏地址。',
  auction_lose: '竞拍失败',
  participate_auction: '推荐你的朋友参与 NFT 拍卖，赢取额外奖励！被推荐人通过你的链接参与拍卖，你可以获得被推荐人竞拍金额的 0.5% 作为推荐奖励。',
  referral_Bonus: '赢取推荐奖励!',
  copy_link: '复制链接',
  share_twitter: '分享到Twitter',
  auction_proceeds: '拍卖收益：',
  recommend_proceeds: '推荐收益：',
  currently_auctioned: '竞得拍品：',
  white_list_congratulations: '恭喜您！您可提前{minutes}分钟参与竞拍',
  bidding_history: '查看竞拍记录',
  nfttitle_auction: 'NFT 拍卖',
  twitter_chare: '点击链接加入 everPay 上的无 gasFee 拍卖，获得独家NFTs。如果你使用该链接加入拍卖，被别人抢拍，你将在百分之102的退款基础上获得额外的百分之0.5的奖励！想获得 Arweave 吉祥物，速来！',
  twitter_tiger_chare: '点击链接加入 everPay 上的无 gasFee 拍卖，获得虎年主题NFTs。竞拍时间：1月27日22:00至1月28日22:00。如果你使用该链接加入拍卖，被别人抢拍，你将在百分之104的退款基础上获得额外的百分之0.5的奖励！通过 =BitKeepOS 参与竞拍更有额外百分之1的奖励！',
  twitter_dragon_turtles_chare: '参与 everPay 与 =ardriveapp 联合推出的NFT 拍卖，可爱龙龟等你来拍！每个 NFT 都是首期 Permaswap 流动性挖矿的入场券。快通过这个链接参与吧！',
  twitter_con_fi_city_chare: '参与 everPay 与 =Conflux_Network 联合推出的 NFT 拍卖活动，可爱烤仔等你来拍！每个 NFT 都是首期 Permaswap 流动性挖矿的入场券。快通过这个链接参与吧！',
  socials_account: '社交媒体账号:',
  min_price: '点击获取拍卖最低价格',
  view_ori_batch_nfts: '查看上批NFTs',
  new_auction_nfts: '查看最新NFTs拍卖',
  tiger_nft: {
    title: 'BitKeep 和 everPay 联合呈现：虎年新气象 NFTs',
    tiger_msg: 'BitKeep 与 everPay 的联名款 NFT 来啦! 快去竞拍一只可爱的小老虎吧!  虎年带来了吉祥、进取与热情，祝大家虎年大吉!'
  },
  distribution: '分润:',
  floorPrice: '地板价:',
  volume: '总交易量:',
  enter: '探索',
  not_start: '未开始',
  event_progress: '进行中',
  auction_closed: '拍卖已结束',
  service_not_available: '服务在您的地区无法使用',
  price_increase: '當前加價金額',
  confirmation: '确认',
  interest_rate: '加价 {interestRate}',
  nft_num_status: '你有拍品已被抢拍，可再次参与拍卖',
  sort_nft: '排序方式',
  price_high_low: '价格降序',
  price_low_high: '价格升序',
  filter_nft: '筛选方式',
  filter_not_end: '未结束NFT',
  filter_end: '已结束NFT',
  please_select: '请选择选项',
  remove_sort: '清除',
  distance_to_end: '距结束:',
  end_hours: '{hours}时',
  end_minutes: '{minutes}分',
  please_wait: '请等待',
  cooling_in_progress: '冷却中',
  past_auctions: '往期拍卖',
  back_prev_page: '返回前页',
  back_to_auction_home: '返回活动首页',
  nft_hot_tip: '该 NFT 过于火热，你可以尝试竞拍其他 NFT。🔥🔥🔥',
  nft_address_err_tip: '请填写 Arweave 地址。若仍使用以太坊地址，且成功拍得 NFT，请联系我们({email})。',
  understand: '我已知晓!',
  price_cap: '价格达到测试上限',
  price_cap_tips: '该 NFT 的拍卖价格已经达到测试设置的价格上限，赶快参与其他 NFT 的拍卖吧！',
  tutorial: '教程',
  tutorial_info: '拍卖就是如此简单!',
  view_video: '查看视频',
  how_get_arweave_address: '怎样获得 Arweave 地址?',
  faucet_page: {
    title: '水龙头',
    msg1: 'everPay 实时支付协议。',
    msg2: '仅需两步即可领取测试币。',
    msg3: '每地址、每 24 小时可领取一次测试币。',
    input_address: '收款地址',
    evm_ar_address: '请输入 Ethereum / Arweave/ Email 格式的地址',
    please_address: '请输入地址',
    post_the_tweet: '转发推特',
    please_tweet_msg: '请使用您的活跃的 Twitter 帐户发送一条推文。',
    open_twitter: '打开推特',
    format_err: '格式错误',
    unable_to_collect: '该地址今天已领取过测试币了，请在 {time} 后再来领取。',
    claim_success: '成功',
    claim_failed: '失败',
    submit: '提交',
    pending: '待确认...',
    everscan_view: '前往 everscan 查看',
    error: {
      err_recaptcha_failed: 'Recaptcha 失败',
      err_acc_not_exist: '收款地址未注册，无法领取',
      err_invalid_id: '无效账户'
    }
  }
}
export default messages
