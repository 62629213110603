
import { computed, defineComponent, ref, onMounted } from 'vue'
import Container from '@/components/common/Container.vue'
import NoticeList from '@/components/common/NoticeList.vue'
import NoticeItem from '@/components/common/NoticeItem.vue'
import { useI18n } from 'vue-i18n'
import BtnArea from '@/components/common/BtnArea.vue'
import { getAccountChainType, handleErrorMsg, isSmartAccount, genEverId } from '../libs/util'
import { ElMessage } from 'element-plus'
import { getAccountAddressTx, submitToken, getAccountData } from '../libs/api'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { isProd } from '@/constants'
import debounce from 'lodash/debounce'
dayjs.extend(duration)
export default defineComponent({
  components: {
    Container,
    NoticeList,
    NoticeItem,
    BtnArea
  },
  setup () {
    const { t } = useI18n()
    const inputAddress = ref('')
    const submitLoading = ref(false)
    const isCollection = ref(false)
    const v2TokenStr = ref('')
    const grecaptchaVisible = ref(false)
    const isInputDisaled = ref(false)
    const addressTxTimer = ref('')
    const addressTxloading = ref(false)
    const addressTx = ref({} as any)
    const addressformatSuccess = computed(() => {
      return getAccountChainType(inputAddress.value)
    })
    const debounceInputChange = debounce(async () => {
      try {
        let acc = inputAddress.value
        if (isSmartAccount(inputAddress.value)) {
          acc = await genEverId(inputAddress.value)
        }
        const addressTxResult = await getAccountAddressTx(acc)
        isCollection.value = !addressTxResult
        if (isCollection.value) {
          await verifyV2Robots()
          grecaptchaVisible.value = true
          addressTx.value = {}
          addressTxTimer.value = ''
        } else {
          addressTx.value = addressTxResult
          intervalUpdateTxTime()
        }
      } catch (e:any) {
        handleErrorMsg(e, t)
      } finally {
        addressTxloading.value = false
      }
    }, 800)
    const inputChange = () => {
      if (getAccountChainType(inputAddress.value)) {
        addressTxloading.value = true
        addressTx.value = {}
        addressTxTimer.value = ''
        debounceInputChange()
      } else {
        isCollection.value = false
        grecaptchaVisible.value = false
      }
    }
    const toDoubleNumber = (index: number): string => {
      if (index >= 10) {
        return `${index}`
      }
      return '0' + index
    }
    let intervalTxTimer = null as any
    const intervalUpdateTxTime = () => {
      intervalTxTimer && clearTimeout(intervalTxTimer)
      intervalTxTimer = setTimeout(() => {
        if (addressTx.value.createdAt) {
          const time = dayjs(addressTx.value.createdAt).unix() + 60 * 60 * 24
          const diffTime = dayjs.duration((time * 1000) - (dayjs().unix() * 1000))
          addressTxTimer.value = `${toDoubleNumber(diffTime.hours())}:${toDoubleNumber(diffTime.minutes())}:${toDoubleNumber(diffTime.seconds())}`
          console.log(addressTxTimer.value, 'addressTxTimer')
          addressTxloading.value = false
        } else {
          addressTx.value = {}
          addressTxTimer.value = ''
        }
        intervalUpdateTxTime()
      }, 1000)
    }
    const submitVerify = async () => {
      isInputDisaled.value = true
      submitLoading.value = true

      try {
        let acc = inputAddress.value
        if (isSmartAccount(inputAddress.value)) {
          acc = await genEverId(inputAddress.value)
          await getAccountData(acc)
        }
        const result = await submitToken(v2TokenStr.value, acc)
        if (result === 'ok') {
          await intervalUpdateAddressStats()
        }
      } catch (e:any) {
        ElMessage({
          type: 'error',
          showClose: true,
          duration: 2500,
          message: t(`faucet_page.error.${JSON.parse(e.message).error}`)
        })
        isInputDisaled.value = false
        submitLoading.value = false
      }
    }
    let timer = null as any
    const intervalUpdateAddressStats = async () => {
      timer && clearTimeout(timer)
      timer = setTimeout(async () => {
        let acc = inputAddress.value
        if (isSmartAccount(inputAddress.value)) {
          acc = await genEverId(inputAddress.value)
        }

        try {
          const addressTxResult = await getAccountAddressTx(acc)
          if (addressTxResult) {
            submitLoading.value = false
            isInputDisaled.value = false
            v2TokenStr.value = ''
            inputAddress.value = ''
            ElMessage({
              type: 'success',
              duration: 3000,
              message: t('faucet_page.claim_success'),
              showClose: true
            })
            isCollection.value = false
            clearTimeout(timer)
          } else {
            intervalUpdateAddressStats()
          }
        } catch {
          intervalUpdateAddressStats()
        }
      }, 5000)
    }

    const verifyV2Robots = async () => {
      try {
        if (typeof window.grecaptcha.render === 'function') {
          console.log(window.grecaptcha, 'render')
          await window.grecaptcha.render('grecaptcha', {
            sitekey: '6LfykL0eAAAAAFxvOPWy5yKjx7q92K1OgNiN9Lhx',
            callback: async (v2Token:string) => {
              v2TokenStr.value = v2Token
              setTimeout(() => {
                grecaptchaVisible.value = false
                window.grecaptcha.reset()
              }, 1500)
            }
          })
        }
      } catch (e:any) {
      }
    }
    onMounted(async () => {
      window.addEventListener('load', async () => {
        try {
          await verifyV2Robots()
        } catch (e:any) {
          verifyV2Robots()
        }
      })
      intervalTxTimer && clearTimeout(intervalTxTimer)
      intervalUpdateTxTime()
    })
    // const openTwitterModal = () => {
    //   if (addressformatSuccess.value && isCollection.value) {
    //     const url = `https://twitter.com/intent/tweet?text=I'm verifying my @everPay address for the testNet ${address.value.trim()}`.trim()
    //     window.open(url, '_blank', 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=600px,height=400px')
    //     setTimeout(() => {
    //       isTwitterChare.value = true
    //     }, 1000)
    //   }
    // }
    return {
      t,
      isProd,
      addressTx,
      submitLoading,
      inputAddress,
      isCollection,
      addressformatSuccess,
      v2TokenStr,
      submitVerify,
      grecaptchaVisible,
      isInputDisaled,
      addressTxTimer,
      addressTxloading,
      inputChange
    }
  }
})
