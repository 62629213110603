import { ElMessage } from 'element-plus'
import { isAddress } from '@ethersproject/address'
import isString from 'lodash/isString'
import zh from '@/i18n/zh'

export const shortAddress = (address: string, count: number): string => {
  return `${address.slice(0, count)}...${address.slice(-count)}`
}

export const waitSeconds = async (seconds: number): Promise<void> => {
  // eslint-disable-next-line promise/param-names
  return await new Promise(resolve => {
    setTimeout(resolve, seconds * 1000)
  })
}

export const formatInputPrecision = (amount: string, precision: number): string => {
  if (amount === '' || amount === undefined || amount === null) return ''
  if (amount === '0') return '0'
  if (isString(amount) && !amount.includes('.')) return amount

  if (isString(amount) && amount.includes('.')) {
    const amountArr = amount.split('.')
    if (amountArr[1].length > precision) {
      if (precision > 0) {
        return `${amountArr[0]}.${amountArr[1].slice(0, precision)}`
      } else {
        return `${amountArr[0]}`
      }
    }
  }
  return amount
}

// 获取小数部分开始 0 的个数
const getFractionalPart0Counts = (fractionalPart: string): number => {
  for (let i = 0; i < fractionalPart.length; i += 1) {
    if (fractionalPart[i] !== '0') {
      return i
    }
  }
  return fractionalPart.length
}

export const formatRate = (rate: string, numberCounts: number): string => {
  const [integerPart, fractionalPart] = rate.split('.')
  if (integerPart.length >= numberCounts) {
    return integerPart
  }
  if (fractionalPart?.length > 0) {
    const needReduceCounts = +integerPart === 0
      // 整数部分是 0，补足小数部分不是 0 前缀的数量
      ? -getFractionalPart0Counts(fractionalPart)
      : integerPart.length
    const fractionalPartUpdated = fractionalPart.slice(0, numberCounts - needReduceCounts)
    return `${integerPart}.${fractionalPartUpdated}`
  }
  return rate
}

export const getErrorMessage = (e: Error, t: any): string => {
  let msgKey = ''
  if (e.message.includes('User denied')) {
    msgKey = 'error.error_deny'
  } else if (e.message.includes('Network Error')) {
    msgKey = 'error.network_error'
  } else if (e.message in zh.error) {
    msgKey = `error.${e.message}`
  } else if (e.message in zh) {
    msgKey = e.message
  } else {
    const matchedEthereumPatterns = (/(\s|\S)+code=(\S+),/gi).exec(e.message)
    if (matchedEthereumPatterns?.[2] !== undefined) {
      const code = matchedEthereumPatterns[2]
      if (code in zh.error) {
        msgKey = `error.${code}`
      }
    }
  }

  return msgKey !== '' ? t(msgKey) : t(e.message)
}

export const handleErrorMsg = (e: Error, t: any): void => {
  ElMessage({
    showClose: true,
    message: getErrorMessage(e, t),
    type: 'error'
  })
}

export const checkParentsHas = (classname: string) => {
  return (node: HTMLElement | null) => {
    while (node != null) {
      if (node?.classList?.contains(classname)) {
        return true
      }
      node = node.parentNode as HTMLElement | null
    }
    return false
  }
}

const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i

const tabletRE = /android|ipad|playbook|silk/i

export const isMobileDevice = (opts?: any): boolean => {
  if (opts == null) opts = {}
  let ua = opts.ua
  if (ua == null && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (ua?.headers != null && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  let result = mobileRE.test(ua) || (opts?.tablet != null && tabletRE.test(ua))

  if (
    !result &&
    opts?.tablet != null &&
    opts?.featureDetect != null &&
    navigator?.maxTouchPoints > 1 &&
    ua.includes('Macintosh') &&
    ua.includes('Safari')
  ) {
    result = true
  }

  return result
}

export interface FormatedBundleInternalRecord {
  internalStatus: string
  formatTime: string
  items: Array<{
    from: string
    to: string
    amount: string
    symbol: string
  }>
}

const isEthereumAddress = isAddress
const isArweaveAddress = (address: string): boolean => {
  return isString(address) && address.length === 43 && address.search(/[a-z0-9A-Z_-]{43}/g) === 0
}
export const isSmartAccount = (account: string): boolean => {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(account)
}
export const getAccountChainType = (from: string): boolean => {
  if (isEthereumAddress(from)) {
    return true
  }

  if (isArweaveAddress(from)) {
    return true
  }
  if (isSmartAccount(from)) {
    return true
  }

  return false
}
const checkSum = async (idBytes: Uint8Array): Promise<Uint8Array> => {
  const hash = await crypto.subtle.digest('SHA-256', Uint8Array.from([...Buffer.from('eid'), ...idBytes]))
  return new Uint8Array(hash).slice(0, 2)
}

export const uint8ArrayToHex = (uint8Array: Uint8Array): string => {
  return [...uint8Array].map((b) => {
    return b.toString(16).padStart(2, '0')
  }).join('')
}

export const hexToUint8Array = (hexString: string): Uint8Array =>
  Uint8Array.from((hexString.match(/.{1,2}/g) as any).map((byte: any) => parseInt(byte, 16)))

export const genEverId = async (email: string): Promise<string> => {
  const str = email.toLowerCase().trim()
  const hash = await crypto.subtle.digest('SHA-256', Buffer.from(str, 'utf-8'))
  const idBytes = new Uint8Array(hash)
  const sum = await checkSum(idBytes)
  const concatArray = Uint8Array.from([...idBytes, ...sum])
  return `eid${uint8ArrayToHex(concatArray)}`
}
