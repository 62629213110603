import { isMobileDevice } from '@/libs/util'
const hosts = {
  WEBSITE: {
    dev: 'dev.everpay.io',
    prod: 'everpay.io'
  },
  APP: {
    dev: 'app-dev.everpay.io',
    prod: 'app.everpay.io'
  },
  FARM: {
    dev: 'farm-dev.everpay.io',
    prod: 'farm.everpay.io'
  },
  SCAN: {
    dev: 'scan-dev.everpay.io',
    prod: 'scan.everpay.io'
  },
  FaucetTest: {
    dev: 'faucet-dev.everpay.io',
    prod: 'faucet.everpay.io'
  }
}

const VUE_APP_PROJECT_NAME = process.env.VUE_APP_PROJECT_NAME as 'WEBSITE' | 'APP' | 'FARM' | 'SCAN' | 'FaucetTest'
export const isEverpayOfficialWebsite = process.env.VUE_APP_PROJECT_NAME === 'WEBSITE'
export const isEverpayApp = process.env.VUE_APP_PROJECT_NAME === 'APP'
export const isEverpayFarm = process.env.VUE_APP_PROJECT_NAME === 'FARM'
export const isEverpayScan = process.env.VUE_APP_PROJECT_NAME === 'SCAN'
export const isProd = window.location.host.startsWith((hosts[VUE_APP_PROJECT_NAME]).prod)
export const everpayAppHost = isEverpayApp ? '' : `https://${hosts.APP[isProd ? 'prod' : 'dev']}`
export const everpayOfficialWebsiteHost = isEverpayOfficialWebsite ? '/' : `https://${hosts.WEBSITE[isProd ? 'prod' : 'dev']}`
export const everpayFarmHost = isEverpayFarm ? '/' : `https://${hosts.FARM[isProd ? 'prod' : 'dev']}`
export const everpayScanHost = isEverpayScan ? '' : `https://${hosts.SCAN[isProd ? 'prod' : 'dev']}`

export const savedI18nStorageKey = 'saved-i18n'

export const CONFIRM_SIGN_MODAL_EVENT = 'CONFIRM_SIGN_MODAL_EVENT'

export const INFURA_ID = 'b6ac62371f004aa2beeb1790f7dcf09f'

export const isMobile = isMobileDevice()

export const CURRENCY_SYMBOL_STACK = {
  CNY: '¥',
  USD: '$'
}
