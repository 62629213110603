import { createRouter, createWebHistory } from 'vue-router'
import FaucetTest from '../pages/FaucetTest.vue'

const routes = [
  {
    path: '/',
    name: 'faucetTest',
    component: FaucetTest
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: async () => await import('../pages/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.afterEach(() => {
  document.documentElement.scrollTop = 0
})
export default router
