
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup (props, context) {
    const click = () => {
      if (!props.disabled && !props.loading) {
        context.emit('click')
      }
    }
    return {
      click
    }
  }
})
