import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './styles/index.scss'
import { importElementPlus } from './element-plus'

const app = createApp(App)

app
  .use(router)
  .use(i18n)

importElementPlus(app)

app.mount('#app')
