
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    needBack: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const router = useRouter()
    const back = () => {
      router.go(-1)
    }
    return {
      back
    }
  }
})

