const messages = {
  app: 'App',
  wallet: 'Wallet',
  bridge: 'Bridge',
  swap: 'Swap',
  sdk: 'SDK',
  light_paper: 'Light Paper',
  NFT_auction: 'NFT Auction',
  resources: 'Resources',
  explorer: 'Explorer',
  lang: 'English',
  done: 'Done',
  launch_app: 'Launch App',
  slogan: 'Real-time financial protocol for the blockchain',
  use_app_connect: 'Use { connectAppName } connected',
  account: 'everPay Account',
  products: 'Products',
  develop: 'Develop',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: 'Help Center',
  privacy_policy: 'Privacy Policy',
  faqs: 'FAQs',
  about: 'About',
  time: 'Time',
  from: 'From',
  to: 'To',
  coming_soon: 'coming soon',
  transfer: 'Transfer',
  transfer_on_everpay: 'Transfer on everPay',
  deposit: 'Deposit',
  depositing: 'Depositing',
  withdraw: 'Withdraw',
  withdrawing: 'Withdrawing',
  transactions: 'Transactions',
  successful: 'Successful',
  failed: 'Failed',
  internal_tx: 'Internal TX',
  swap_records: 'Swap Records',
  contacts: 'Contacts',
  connect: 'Connect Wallet',
  disconnect: 'Disconnect',
  connect_wallet: 'Choose Wallet',
  connect_wallet_info: 'Connect an Ethereum, Arweave or Moonbeam wallet to deposit and manage funds.',
  asset: 'Asset',
  assets: 'Assets',
  assets_connect_msg: 'By connecting a wallet to deposit and manage assets.',
  receiver: 'Receiver',
  amount: 'Amount',
  balance: 'Balance',
  fee: 'Fee',
  copy: 'Copy',
  edit: 'Edit',
  delete: 'Delete',
  add: 'Add',
  modify: 'Modify',
  filter: 'Filter',
  all: 'All',
  all_currencies: 'All',
  transaction_type: 'Type',
  balance_mark: 'Balance: ',
  immediately_add: 'Immediately add',
  network: 'Network',
  choose_network: 'Choose Network',
  choose_wallet: 'Choose Wallet',
  choose_token: 'Choose Token',
  choose_contact: 'Choose Contact',
  spread_percent: 'Spread',
  insufficient: 'Insufficient balance',
  click_deposit: 'please deposit',
  click_details: 'learn more',
  complete: 'Successful',
  everpay_balance: 'Balance on everPay',
  chain_balance: 'Balance on {blockchain}',
  transfer_amount: 'Amount',
  receive_account: 'Receiver Account',
  send: 'Send',
  receive: 'Receive',
  chain_type: 'Chain',
  support_chain_type: 'Supported Chain',
  symbol: 'Symbol',
  max_amount: '(Max)',
  system_upgrading: 'everPay\'s system is just upgrading, please wait a moment',
  myaccount: 'My Account',
  no_data: 'No data found',
  current_account_address: 'Current everPay account address',
  contact_exists: 'Contact address exists',
  no_contacts: 'The contact list is empty',
  edit_contact: 'Edit contact',
  add_contact: 'Add contact',
  delete_contact: 'Delete contact',
  contact_name: 'Contact name',
  contact_account: 'Contact address',
  new_address_detected: 'New address detected! Add contact',
  edit_success: 'Edit was successful!',
  add_success: 'Add was successful!',
  delete_success: 'Delete was successful!',
  sign_message: 'Sign Message',
  confirm: 'Confirm',
  cancel: 'Cancel',
  total_cost: 'Total Cost',
  swap_out_amount: 'Pay Amount',
  swap_in_amount: 'Receive Amount',
  rate: 'Rate',
  slippage: 'Slippage',
  swap_success: 'Swap successful',
  connect_success: 'Connect successful',
  disconnect_success: 'Disconnect successful',
  view_swap_records: 'View swap records',
  swap_failed: 'Swap Failed',
  swap_refund: 'Refund',
  swap_slippage: 'Slippage Tolerance',
  not_found: 'Error 404',
  not_found_desc: 'Page Not Found',
  back_to_home: 'Back to homepage',
  transfer_receipt_support: 'Support ethereum, arweave account',
  withdraw_receipt_chaintype_support: 'Enter {chainType} address',
  quick_withdraw_mode: 'Quick Mode',
  quick_withdraw_mode_with_time: 'Quick Mode (estimated {time} seconds)',
  refunded: 'Refunded',
  quick_withdraw_mm: 'Quick Withdraw Market Maker: ',
  quick_withdraw: 'Quick Withdraw',
  quick_withdraw_data_not_inited: 'Data not initialized',
  quick_withdraw_long_time: 'Quick withdrawals queue is too long, this feature has been suspended for now',
  quick_withdraw_refund: 'Refund',
  expected_receive: 'Expected to receive',
  actual_fee: 'Fee',
  actual_receive: 'Received',
  insufficient_quick_withdrawal: 'Insufficient Quick Withdrawal',
  token_insufficient_quick_withdrawal: 'Insufficient {token} Quick Withdrawal',
  quick_withdraw_info_url: 'https://everpay.zendesk.com/hc/en-us/articles/4404465610137-everPay-Quick-Withdrawal',

  pls_not_transfer_to_exchange: '"Transfers" are done via the everPay network wallet, please do not transfer to exchanges or contract addresses, it will be lost!',
  pls_not_withdraw_to_exchange: 'Please refrain from withdrawing assets to an exchange or contract address as they may be lost.',
  pls_use_ethereum_wallet: 'Please install MetaMask or use Ethereum wallet to open',
  pls_install_arweave_wallet: 'Please install Arweave Wallet',
  pls_change_to_mainnet: 'Please Change Ethereum to Mainnet',
  pls_change_to_kovan: 'Please Change Ethereum to Kovan Testnet',
  copy_success: 'Copy Successful',
  introduction_to_everPay: 'Introduction to everPay',
  balance_info_url: 'https://everpay.zendesk.com/hc/en-us/articles/4405069888537-What-is-everPay-balances-',
  total_assets_of: 'With total assets of',
  invalid_name: 'Invalid name',
  war_more_info: 'Wrapped AR is an ERC-20 token backed 1:1 with AR, which means one wrapped AR is always equal to one AR',
  balance_more_info: 'everPay is like a Layer-2 protocol. Your everPay balance is live in a separate space called everPay',
  quick_mode_more_info: "The everPay team contacted external market maker teams and realized quick withdrawals through everPay transfers to save users' time and cost of withdrawals.",
  support_token_search: 'Support for Token Symbol Search',

  transfer_page: {
    success: 'Transfer successful',
    view_transfer_txs: 'View transfer transactions',
    nofunds: 'No funds in current asset, ',
    intro: 'How it works？',
    input_amount: 'Input transfer amount',
    transfer_detail: 'Transfer Details',
    click_url: 'https://news.ever.vision/everpay-a-trusted-cross-chain-payment-protocol-eba4a0af7d66'
  },
  deposit_page: {
    pending_balance: 'Pending',
    valid_balance: 'Available',
    balance: 'Balance',
    step1: 'Deposit tx confirmation',
    step2: 'Confirmed by {waitBlock} blocks',
    input_amount: 'Input deposit amount',
    war_info_1: 'When connecting through an Ethereum wallet, select AR and you will use',
    war_info_2: 'to recharge for AR.',
    deposit_info: 'Deposit on-chain assets such as Arweave, Ethereum, Moonbeam, etc. to everPay.'
  },
  withdraw_page: {
    withdraw_amount: 'Amount',
    withdraw_address: 'Address',
    withdraw_network: 'Network',
    withdraw_to: 'To',
    withdraw_time: 'Normal withdrawals will take {time} minutes to confirm across the chain.',
    withdraw_fee_notice: 'Insufficient withdrawal fee',
    valid_balance: 'Balance: ',
    quick_withdraw_limit: 'Quick withdrawal balance: ',
    step1: 'AR record confirmation',
    step2: 'Withdraw tx confirmation',
    quick_step1: 'Market maker has received',
    quick_step2: 'Fast withdrawal tx confirmation',
    input_amount: 'Input withdraw amount',
    withdraw_detail: 'Withdraw Details',
    war_info: 'Withdraw AR to the ethereum blockchain will get',
    war_info_url: 'https://everpay.zendesk.com/hc/en-us/articles/4404199743257-Wrapped-AR',
    withdraw_info: 'Withdraw assets from everPay to destination chain, eg. Ethereum and so on.'
  },
  transactions_page: {
    done: 'Done',
    in_progress: 'In process',
    ar_record: 'AR record',
    deposit_record: 'Deposit record',
    withdraw_record: 'Withdraw record',
    refund_record: 'Refund record',
    quick_withdraw_record: 'Quick withdraw record'
  },
  account_page: {
    account_id: 'Account ID',
    account_detail: 'Account Detail',
    everpay_asset: 'Everpay Assets',
    chain_asset: 'Chain Assets'
  },
  swap_page: {
    swap_detail: 'Swap Details',
    submitted: 'Submitted',
    ws_connecting: 'The system is connecting, Please refresh or reload this page to try again.'
  },
  genqr_page: {
    qr_code_tips: 'Please select the receipt asset, generate receipt QR code, save the receipt code to accept payments easily.',
    generate_qr_code: 'Generate Receipt QR code',
    receipt_code: 'Receipt code',
    collections: 'Receive {symbol}',
    scan_qr_code: 'Scan the QR code and Transfer {symbol} to it',
    save_the_screenshot: 'Please save screenshots',
    wallet_account: 'Wallet Account',
    fill_account: 'Auto-fill the receipt address after connecting the wallet'
  },
  error: {
    error_zero: 'The amount should be greater than 0',
    error_less_fee: 'The amount should be greater than fee',
    error_receiver: 'Wrong receiver address',
    error_receiver_chain_type: 'Wrong {chainType} address',
    error_transfer: 'Transfer failed',
    error_withdraw: 'Withdraw failed',
    error_deny: 'Transaction was rejected by user',
    deny_connect: 'User rejected the request',
    quick_withdraw_chain_type: 'Quick withdrawals to {chainType} blockchain are not supported',

    less_then_zero: 'The amount should be greater than 0',
    invalid_address: 'Wrong receiver address',

    err_eth_tx_minted: 'Executed transaction',
    err_nil_amount: 'Nil amount',
    err_zero_amount: 'Zero amount',
    err_negative_amount: 'Negative amount',
    err_larger_amount: 'Amount too larger',
    err_larger_data: 'Data too larger',
    err_insufficient_balance: 'Insufficient balance',
    err_self_transfer: 'Transfer to yourself is not allowed',
    err_invalid_amount: 'Invalid amount',
    err_invalid_fee: 'Invalid Fee',
    err_invalid_tx_type: 'Invalid transaction Type',
    err_invalid_owner: 'Invalid owner',
    err_invalid_address: 'Invalid address',
    err_invalid_nonce: 'Invalid nonce',
    err_invalid_signature: 'Invalid signature',
    err_invalid_mint_tx: 'Invalid mint',
    err_invalid_locker: 'Invalid locker',
    err_invalid_tx_version: 'Invalid tx version',
    err_token_not_found: 'Token not found',
    err_invalid_chainid: 'Invalid chainID',
    err_tx_minted: 'Tx minted',
    err_negative_fee: 'Invalid Fee',
    err_invalid_action: 'Invalid Action',
    err_invalid_eth_tx: 'Invalid ETH Tx',
    err_invalid_erc20_tx: 'Invalid erc20 Tx',
    err_invalid_eth_address: 'Invalid ETH address',
    err_invalid_account_type: 'Invalid account type',
    err_nonce_too_low: 'Nonce too low',
    err_invalid_transaction: 'Invalid transaction',
    err_limit_exceeded: 'Requests are too frequent, please try again later',
    err_node_syncing: 'Wait for system\' synchronization to complete',
    err_system_upgrade: 'The everPay system is just upgrading, pelease hold on a moment',

    // express
    err_no_enough_fee: 'Insufficient quick withdrawal fee',
    err_wallet_no_enough_fund: 'Insufficient quick withdrawals for market makers',
    err_unsupport_withdraw_chain_type: 'Quick withdrawals to {chainType} are not supported',
    err_unsupport_token: 'Not supported {token} Quick Withdrawal',

    // swap
    err_no_tx_data: 'no tx data',
    err_invalid_tx_data: 'tx data is invalid',
    err_invalid_app_id: 'app id is invalid',
    err_invalid_order: 'order data is not valid',
    err_invalid_amout: 'invalid tx in or out amount',
    err_no_order: 'no ordr posted',
    err_no_tx_type: 'no tx type',
    err_miss_swap_tx_data: 'miss swap tx data',
    err_no_pool: 'no available pool',
    err_invalid_token: 'invalid token',
    err_swap_race_condition: 'swap is race condition',
    err_everpay_tx: 'failed to post everpay tx',
    err_miss_parameter: 'miss parameter',
    err_unkown: 'unkown or unexpect swap error',
    err_insufficient_fund: 'Insufficient market maker liquidity for this trade, pelease reduce the amount of swap',
    err_token_out_amount_too_small: 'price changed, do not meet this amount of swap, please try again',
    error_invalid_order: 'invalid order',
    network_error: 'Network error, please try again later',

    // web3
    CALL_EXCEPTION: 'Call exception',
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    NETWORK_ERROR: 'Ethereum network not matched',
    NONCE_EXPIRED: 'Nonce expired',
    REPLACEMENT_UNDERPRICED: 'Replacement underpriced',
    UNPREDICTABLE_GAS_LIMIT: 'Unpredictable gasLimit',

    INVALID_ACCOUNT_TYPE: 'Invalid account type'

  },

  // new swap
  err_not_found_path: 'Not found path',
  err_not_found_salt: 'Not found salt',
  err_not_found_lp: 'Not found LP',
  err_no_authorization: 'No authorization',
  err_duplicate_registration: 'Duplicate registration',
  err_invalid_msg: 'Invalid message',
  err_invalid_token: 'Invalid token',
  err_invalid_order: 'Invalid order',
  err_invalid_address: 'Invalid address',
  err_invalid_signature: 'Invalid signature',
  err_invalid_paths_or_bundle: 'Invalid paths or bundle',
  err_invalid_pool: 'Invalid pool',
  err_no_pool: 'No pool',
  err_no_lp: 'No LP',
  err_no_path: 'No path',
  err_invalid_amount: 'Invalid amount',
  err_out_of_range: 'Out of range',
  err_invalid_number: 'Invalid number',
  err_no_implement: 'No implement',
  err_invalid_path: 'Invalid path',
  err_invalid_swapouts: 'Invalid swapouts',
  err_invalid_pool_paths: 'Invalid pool paths',
  err_invalid_price: 'Invalid price',
  err_invalid_liquidity: 'Invalid liquidity',
  err_invalid_price_direction: 'Invalid price direction',
  err_invalid_fee: 'Invalid fee',
  err_invalid_swap_user: 'Connection address is the same as LP node address, resulting in failed transactions. Please change the account.',

  PST_WITHDARW_TO_ARWEAVE_MUST_BE_INTEGER: 'PST Token withdrawals to Arweave must be integers',
  // NFT
  nft_error: {
    err_invalid_appId: 'Invalid appId',
    err_invalid_action: 'Invalid action',
    err_bid_self: 'You already have your current NFT, no need to take another one',
    err_invalid_referredBy: 'Invalid referrer address',
    err_invalid_nftRecipient: 'NFT collection address is invalid',
    err_invalid_tx: 'Invalid transaction',
    err_invalid_nftWallet: 'Invalid artist wallet address',
    err_invalid_amount: 'Invalid bid amount',
    err_invalid_token: 'Invalid token',
    err_invalid_path: 'Invalid path',
    err_not_found_auctionId: 'Missing NFT auction batches',
    err_not_found_nftId: 'Missing NFT ID',
    err_not_during_event: 'Auction time does not match',
    err_not_recaptcha: 'Lack of validation',
    err_more_than_bidLimit: 'Cannot bid on more than {bidLimit} NFTs at the same time',
    err_bid_too_low: 'Bids must not be less than 5% of the total price, please re-bid',
    err_bid_too_high: 'The bid exceeds the total price by 20%, please reduce the amount of the auction',
    err_more_than_ipLimit: 'Current IP is restricted, try again later',
    err_recaptcha_failed: 'Verification failed, please try again!',
    err_add_price: 'Sorry, the price is no longer valid, the current price is {amount}, please re-add the price.'
  },
  artist_info: {
    artist_information: 'Artist Information',
    introduction: 'Introduction',
    past_work: 'Past Works',
    more_about: 'More about',
    inner_motion_important: 'Alien Art is a Digital Art Label born from a need for free personal expression.',
    inner_motion_general_info: 'All the inspirations come from life experiences, colors and sounds, but mostly from the concept of Sacred Geometry. Geometry is in everything around us, from the smallest object or living thing to the largest astronomical forces and there is always a balance between shapes, proportions and symmetries.Often in collaboration with DJs and Music Festivals around China and Europe, Alien Art is shared through social medias, art exhibitions and music labels. “Enjoy Life, Share & Care”.',
    inner_motion_general_info2: 'A series of animations inspired by one of the oldest form of art in human history, Mandala. Used as sacred guidance tool to help embark on a spiritual journey starting from the outside to the inner core. This collection embraces this ancient tradition and expresses a true balance between geometric shapes and precise movements.',
    micro_plants_important: 'Noelia Puig (1995, Spain) is a digital content creator who works under the pseudonym nueps.',
    micro_plants_general_info: 'Her artistic process and her projects are defined by her interest in absurd, dream-like and surreal imagery. Using fluorescent colors and digitally manipulating highly abstracted versions of the natural (and human-made) world, nueps creates an alternate universe that ranges from fantastical-psychedelic creatures to reinventions of the most every day, mundane objects.',
    ar_drive_important: 'The auction was jointly launched by everPay and ArDrive. ArDrive is built upon the Arweave blockchain, which provides game changing technology that enables any type of digital data to be stored for at least 200 years but probably much longer. Once your most important files are uploaded through ArDrive they will outlive you, your children, and your great-grandchildren. =Xavizedd from the ArDrive community created Dragon Turtle NFTs for the auction.',
    conflux_important: 'Conflux is a permissionless Layer 1 public blockchain connecting decentralized economies across borders & protocols and operates as a PoW/PoS hybrid chain. Built with a unique Tree-Graph consensus mechanism — a dynamic block structure is used to facilitate the processing of 3000–6000 TPS without sacrificing decentralization or security. As the only regulatory compliant, public, and permissionless blockchain in China, Conflux provides a unique advantage for projects building and expanding into Asia. As well as, Conflux aims to connect decentralized economies to strengthen the overall DeFi ecosystem globally. ',
    akord_important: 'Akord invited artist Serge Seidlitz to create a set of 9 NFTs to celebrate the launch of the new smartweave version of Akord Protocol. The Crypto Slang Gang was his creation, and these iconic characters will be the first Atomic Arweave NFTs minted by Akord! Serge Seidlitz is represented by global creative agency B&A. During his 20-year career, his clients have included, The New York Times, The Washington Post, The Guardian, British Airways, Apple, Sony, Samsung, MTV, Coca-Cola, Uber, and KFC.',
    arks_important: 'Arks are a collection of the first 43 ships to reach Yowunas Maias after hundreds of generations of searching for a place to settle and build life. Hosted on Arweave and minted on Ethereum, Arks are early adopter passes for decent.land – a stack of social and identity protocols for web3 dapps. Ark holders are whitelisted for future decent.land collections, tokens, and more. Beyond utility in the protocol, Arks are a prelude to the story told so far through decent.land lore. Each ark’s metadata contains a rich backstory from writer and art director Lee Tyrrell, with traits based on events in the narratives. The visual artist behind Arks is jrdsctt, a prolific graphic designer and photographer known for glitchy fine art 1/1s and innovating on how art can be manipulated with code.',
    birdsong: {
      href_msg: 'collections',
      important_msg1: 'A Guide to the Birdsong is a series of albums inspired by the song of endangered birds, created to raise awareness and funds for their protection. After the huge success of previous sold-out collections, this new compilation launched on Everpay includes nine bird songs. It’s your chance to fight for their protection!',
      important_msg2: 'All those bird songs will be stored for eternity on the Arweave blockchain.',
      important_msg3: 'This project is curated by the non-profit organisation Shika Shika and the music artist El Búho. 100% of the revenues will be used to preserve endangered bird species.',
      important_msg4: 'PIANITY',
      important_msg5: 'Pianity aims to support positive-impact actions through music and is proud to display for the third time a collection of this beautiful Birdsong project. See all previous sold-out ',
      important_msg6: 'Pianity is a music NFT marketplace where musicians and their communities gather to create, collect, and trade songs in limited editions.',
      important_msg7: 'Pianity’s pioneering approach which includes free listening for all and permanent storage of both the smart contract and the music files enables a deeper connection between the artist and his audience. Pianity is backed by Arweave – the next generation of decentralized networks dedicated to permanent data hosting.',
      important_msg8: 'NFTs will be used as entry tickets to liquidity mine on Permaswap.',
      important_msg9: 'In addition, the winner of each auction will be granted the highest DAO rank on our Discord to participate in the governance of Pianity.'
    }
  },
  auction: {
    emilie: 'Emilie is positive about life and the future, even the whole world. The garland on her head is the symbol of her innocence, and she is the angel to the whole Winston family. ',
    vincent: 'Cool but stable glasses is the feature of Vincent. Always being calm and looks like an intellectual that can figure out anything. But he is so inflexible that family always tries to get away from him.',
    enola: 'Enola is shy all the time, but the earrings reveal her rebellious heart. To the family, she is a good girl, shy, not talkative, loves reading, but she will laugh out loud with friends privately.',
    ronney: 'Ronney is bohemian, he likes to trick others. But inside of his mind and personality, he is a great brother who loves to help others and always stand up for others.',
    peggy: 'Peggy is the baby in the family. Cute but behaving in a spoiled manner, the family is bias toward  her. But Ronney always makes Peggy cry so that most of the family member want him to get away from Peggy. However, Ronney loves Peggy, and he  would protect her and care her without hesitation.',
    aron: 'Aron is born to be  an athlete. He never takes off his baseball cap in order to let others not forget he is good at baseball. Meanwhile, he is optimist as well.',
    yvan: 'Yvan, as the eldest in the family, is respected by everyone. Also, Yvan loves the whole family, and Santa\'s look is the best love for all of them. He will surprise everyone with his red packet on his back. Meanwhile, only Yvan can appease Peggy when she is crying.',
    george: 'George seems like a young child even he is grown, but with childish thoughts and behaviors. He is more likely a dreamer, living in his own enjoyable world. But in the family, everyone always  tries to protect his childlike dreams.',
    oscar: 'George seems like a young child even he is grown, but with childish thoughts and behaviors. He is more likely a dreamer, living in his own enjoyable world. But in the family, everyone always  tries to protect his childlike dreams.'
  },
  tiger_nft: {
    title: 'BitKeep and everPay present: YEAR OF THE TIGER NFTs',
    tiger_msg: 'BitKeep and everPay\'s new collection is ready to go! Try and snag a cute little tiger with feeless bids! Someone born in a tiger year is kind, adventurous and enthusiastic. Happy year of the Tiger to you all!'
  },
  pierre_nft: {
    title: 'A modern pottery collection with neolithic vibes',
    inception: 'Red pottery. The "violin-shaped" figures are ubiquitously present in the entire neolithic culture. Now they are popping into the Metaverse.',
    dictators_place: 'Black pottery with kaolin inlays. A trash can that is #nottrashart. The Pandora\'s box of our times.',
    chartzilla: 'Red pottery. Praise the goddess of exchanges.',
    praying_mantis: 'Black pottery. Sometimes love can be harsh. A reinterpretation of "The Thinker" and "The sitting women", anthropomorphic figurines made around 5000BC. The essence of form.',
    greed: 'Red pottery. Greed is the same, no matter the age or the angle you will look upon it.',
    memory: 'Red pottery. A simple clay figurine that catches the intersection of the two main mediums of expression from my collection: pottery for the eternal life of the physical and #Arweave for the eternal life of the digital representation.',
    pepe_on_fur: 'White pottery. This is a funny one. A play between Burt Reynold\'s iconic pictorial, and the piece made by =Vince_Van_Dough in his quarrel with the furries.',
    facing_the_past: 'White pottery. Occasionally, old gods could be terrifying. Who knows what Munch saw...',
    keks_Mother: 'White pottery. Even ancient Egyptian gods have their ancestors. Memes are older than one can think of.'
  },
  chengliang_nft: {
    title: 'Wonderland and the Funny Fellows',
    tyger: 'Tyger is from series "Wonderland and the Funny Fellows". A grumpy alien tiger that is showing off its pointy teeth.',
    miserable: 'Miserable is from series "Wonderland and the Funny Fellows". A very unfortunate funny fellow who is trying to run away from a disastrous fire.',
    spreader: 'Super Spreader is from series "Wonderland and the Funny Fellows". A funny Fellow who is unintentionally becoming a super spreader of a deadly contagious virus.',
    doctor: 'An alien doctor holds a vaccine syringe. This portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows".',
    maniac: 'Dr. Maniac rides bomb as always. This is a tribute to Dr. Strangelove. The portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows".',
    loser: 'A Funny Fellow just gives up on any effort. This portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows".',
    musician: 'A musician Funny Fellow plays a classic song. This portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows".',
    mastermind: 'AR Mastermind finally gets his precious Arweave, and he is ready to conquer the galaxy. This portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows". This NFT is an everPay & Arweave special edition.',
    masker: 'No one has ever seen who is behind that mask! This portrait is from Tiger Cai’s ongoing series "Wonderland and the Funny Fellows".'
  },
  breaking_panda_nft: {
    title: 'Breaking Panda and everPay Give You A Hi-five',
    coronation: 'Breaking panda finally has his crown and is ready to meet the challenge from the virus. Let us look forward to the different courage and good luck!',
    quarantine: 'During this special holiday, Breaking Panda needs to dress up every day at home, and he will also hold an online food sharing meeting!',
    breakfast: "On a special morning with breaking panda, let's have a good phone call, he has a good dream to share with you, guess what he dreamed?",
    summer: "In the pinky world, let's take a look at the new look of Panda. The most important thing is that she has pink watermelon and delicious bamboo. This is really a surprise for us!",
    ar_panda: "Everyday is full of energy with the company of AR, let's enjoy the music and dance with super breaking panda!",
    storm: 'Breaking Panda is a dopamine generator, and every day with breaking panda, there is a colorful beam of light in the mood!',
    son: 'What magic will there be when Breaking Panda meets the Son of Man?',
    lfg: 'Breaking Panda starts interstellar travel with his favorite bamboo energy, come and follow in the footsteps of magic!',
    creation: 'When the gamepad can become a daily tool for creation, can we live in a world decorated by ourselves every day?'
  },
  inner_motion_nft: {
    title: 'Inner Motion',
    arweave_series: 'Alien Art celebrates Spring with a series of blooming mandalas. This NFT is part of the “Inner Motion” original series. An Arweave NFT special edition not to be missed.',
    beacon_series: 'This animated composition is an interstellar beacon for extraterrestrial intelligence. We want to believe!',
    clock_series: 'With this animated mandala clock I will bring you to the fourth dimension, TIME. An irreversible succession of events from the past, through the present and straight into the future.'
  },
  micro_plants: {
    title: 'Microplants',
    micro_plants_1: 'bell, stethoscope, lamp',
    micro_plants_2: 'JellyFish, Trumpet, Spider',
    micro_plants_3: 'carnivora, trophy, triplet',
    micro_plants_4: 'lettuce, eye, layers',
    micro_plants_5: 'Arweave, heart, harlequin',
    micro_plants_6: 'sun, maze, starfish',
    micro_plants_7: 'terrarium, bonsai, slug',
    micro_plants_8: 'Egg, claw, octopus',
    micro_plants_9: 'pants, bow, honeycomb'
  },
  dragon_turtles: {
    title: 'Dragon Turtles',
    bixi: 'Bixi is the oldest and wisest of the Dragon Turtles, a source of guidance and mentorship.',
    zhang: 'Zhang is an adventurous Dragon Turtle with an affinity for slurpees.',
    zuo: 'Zuo comes from a royal line of Dragon Turtles, a bearer of good luck.',
    lulu: 'Lu Lu has a mean jumpshot and can dice vegetables in midair with his blade.',
    xibi: 'Xibi shreds pentatonic scales on his axe and knows a thing or two about heavy metal.',
    yuan: 'Yuan will test his luck while defending the blockweave with his plasma sword.',
    xuanwu: 'Xuanwu ignites hearts and minds with his Inferno fireball. NFT holder will receive a specially customised Permapunk (a NFT) air-dropped by ArDrive, in the next stage.',
    feifei: 'Fei Fei is serene and brings many blessings to his friends.',
    ao: 'Ao is the most knowledgeable Dragon Turtle. In his spare time, he tends to his koi pond.'
  },
  con_fi: {
    title: 'Step on the journey to the end of the world with a dream in mind. Each ConFi represents a life attitude. ConFi wants to show everyone a positive outlook on life, and hope that every image of ConFi can accompany everyone in the journey of life.',
    teletubbies: 'Gem with a heart of gold',
    superhero: 'Unstoppable wisdom and bravery',
    lonely_warrior: 'Might cry while sleeping, but wakes up with new hope',
    great_love: 'Want\'s to tell "I love you" to the whole world',
    pirate_king: 'Determined to find meaning on the open voyage',
    doctor_strange: 'Omnipotent, exploring the mysteries of space and time',
    praise: 'Praising others with the face of an angel and wants to be treated gently in this world',
    cutie: 'High as the sky, wide as the sea, everywhere all at once',
    curious: 'Infinite curiosity of all things'
  },
  crypto_gang: {
    title: 'The Crypto Slang Gang is an exploration into web3, with each character representing popular memes or slang within the space. Each artwork has also been printed as a  50x50cm art giclée print, hand signed by Serge, and postage will be accessible worldwide.',
    the_whale: 'When the Whale makes a transaction, everyone on Twitter knows about it. Gobbling up crypto like they\'re plankton wherever he goes.',
    the_bear: 'The Bear is a pessimist. When the chips are down, he\'ll enjoy giving you the "I told you so". Best to ignore him and get back to work.',
    the_bull: 'When the Bull\'s in town it\'s party time. Everyone loves the Bull. He\'s always pumped and up for whatever, but he\'s also known to crash hard.',
    rekt_guy: 'Rekt Guy is wrecked – the Bear has swiped his stash. The crypto market is slightly rekt right now, too. Where\'s the Bull at?',
    paper_diamond: 'Thanks to the paper hands who sold at the bottom. Diamond hands is always right there to buy those tokens off him.',
    high_priest: 'The High Priest of the Crypto Moon Gods always knows which project is going to 100x next. Make sure to make him your friend before your next trade.',
    gm: 'Always on Twitter and Discord, he\'s happy to greet you at any time of the day with an abbreviated Good Morning: GM. Enough said.',
    fud: 'Have you ever been infected with FUD? FEAR! UNCERTAINTY! DOUBT! It’s everywhere in crypto and it\'s crippling, so beware.',
    degen_guy: 'Your degenerate investor guy who just yolo\'d his last dollars into the latest pump and dump. He\'s the guy screaming MOON into all his social media feeds.'
  },
  arks_decent_land: {
    title: 'Arks are a collection of the first 43 ships to reach Yowunas Maias after hundreds of generations of searching for a place to settle and build life. Hosted on Arweave and minted on Ethereum, Arks are early adopter passes for decent.land – a stack of social and identity protocols for web3 dapps. Ark holders are whitelisted for future decent.land collections, tokens, and more.',
    the_cryochambers: 'Most of the time, our people exist with a constant mortality as a shadow to their lives. Put simply, they’re scared to die. Who isn’t? That’s the natural order of things. Yet, to some, the end can’t be contemplated; there’s too much work on the slate. Subverting the astral timing of fate is routine for the ruling class. Monarchs develop disease, demand a chamber and wait for, “Land ahoy!” Two political parties swap the reins of the fleet each year, and the opposition civil servants spend their break in stasis. Some office workers are intelligent with savings, and make the most of a meagre salary. Those who earn enough can preserve their parents, a pet or themselves. The process is notably traumatic, however. Many can’t forgive their children for funding the morbid ordeal. On paper, it is remarkably efficient; reports of bio-feedback errors continue to fall with each generation. The dutiful staff of this ark are friendly, but wouldn’t slip into a chamber themselves. We all applaud their efforts, and successful water retention is always improving. It’s just they’ve seen too many cadavers enslimed upon the thawboard. I’ve heard that teeth remain in the gloop, and clack to the ground as fleshy liquid spreads across a gurney. At present, The Cryochambers house our Scout Ark bridge crew, kin of the cityfolk, a procession of Whigs and King Qualat — our eldest and most respected regent, awaiting the dawn of A Decent Land.',
    neighbourhood_watch: 'A proud and magnificent daughter is she, Gūlyu Aiak’us — a guardian city. We have a district of urban arks that provide residential comfort, and they vary in flavour and size; each with their own intricacies. But Gūlyu Aiak’us, our Neighbourhood Watch, hides amongst their number; ready to blast invasion fleets with an impressive array of munitions. A functioning citadel, with various territories, this ark would appear innocuous enough. Attackers — rare though they are — zone in on the cluster of municipalities. They imagine civilians are weak, and a military response would be delayed. In general, they’re right — if not for Aiak’us; praise to our marvellous saviour!Many an onslaught has ended in victory, thanks to Gūlyian guile. Her forward cannon slides out from a beak at the bow, loaded with quantum torpedos. Defence capabilities are low, truth be told, because of her front-loaded weapons. In concept, Aiak’us will devastate aggressors before they have chance to respond. If a second wave follows, our primary fighters — The Lieutenant and two of his Pawns — are usually ready to cover their mothership; having flown from protection rotation ‘round Parliament to aid in the ongoing battle.She’s host to a modest population of paupers, forced to live in fear. Indeed, they can’t afford any better, so tend to favelas on the smooth spine of danger. They get certain benefits, of course; food welfare, funeral packages and free psychotherapy. Riots are common, but Aiak’us Rangers are top of our Graviball league.',
    immaculate_laboratory: 'It’s been a tough six-hundred generations; especially for arks at the forefront. Intermittent attacks, solar flares, celestial impact damage and worse have come and gone at regular intervals; left our number decimated. Those that survive are diseased or enwebbed, and oftentimes charred by our enemies. An exception to that is the Immaculate Laboratory; a cowardly hovel of dreamers. Within, its tinkerers work on a cure to the viral infection that eats at our bioships. They also work with Biologists, Analysis and — sometimes — Engineering. Yet, their association with Alternative Propulsions is strained and antagonistic. They’re key to coordinating efforts with invaluable scientific arks, and they know it. Without them, we’d have no hope of successful Arrival; we’d flounder and drown. Their greatest achievement is practical alchemy, a malleable, liquid smog. From waste, they craft long strands of this matter, and deliver it ‘round the whole fleet. With it, we fashion new dinghies and schooners that handle outstanding repairs. The Immaculate Laboratory turned water to wine by inventing the smog, which also feeds us. A Smog Roast is a weekly tradition for families entrenched in our urban district. The matter is rationed, its formula secret and military stocks get first priority. And so, we protect them — begrudgingly, constantly — and silently hope they’ll succumb to disease. Alas, their ark is mostly metallic, with vast glass domes and programmable tendrils. They’re safe in their cubicles, and live to old age, but then they retire and move to the back of the fleet; at least three waves from the front!',
    the_crimson_castle: 'Our long-forgotten native planet — from what I understand — was inundated with royal families, ever at war with each other. Most were stubborn when The Cataclysm came, and only a few joined the exodus. The Crimson Castle is a remnant of history, and it’s home to a family of regents. The eldest — King Qualat — is beloved fleet-wide, but his public appearances are largely symbolic, and conveyed through the use of sapient holograms. For five generations, he’s slept in a chamber; approximately ten years away from his death. His daughter, the honourable Princess of Crimson, went missing soon after he checked himself in. That left Prince Qualat, a damnable fool, to the throne without any challenge. Public debates with his holographic father were commonly broadcast, and made for great viewing. Eventually, King Qualat ver. 3.4.15 demanded his son was ousted, and banished to one of the military ships. The ensuing rebellion was swift and clean, though the Prince made threats; directed at the chambers. He ordered Aiak’us to obliterate them wholesale, and was most surprised when she didn’t. By that point, our fleet was sick of his whinging (and treason seemed something to do, at the time). He was last seen spiralling, out of control, precariously orbiting an exoplanet. A trio of lovely daughters was his parting gift to the fleet. Today, our holographic king has lost count of his various versions. The Princess of Crimson, in all her glory, was never seen again.',
    barracks: 'Our barracks form a city of its own, regimented to the very last inch of its length. Clocks run on time, and the trains tick along; barbers use cutthroats and recycling is mandatory. The air, I’m told, is the cleanest in the fleet — though the residents of Zaļā Yowusta claim otherwise. No privilege is too rich for our soldiers, and they repay that kindness with sacrifice. Enlist, and you guarantee comfort; or so the posters say. In truth, they should read, “Enlist, and you guarantee your death”. Every year, a class of graduates joins the crew of a Pawn; a light munitions model that we craft from braids of smog. If their Pawn can weather the storm of deep reconnaissance, attack and debris, the best of the team are transferred to Aiak’us or — sometimes — employed in The Scarlet Guard. Exemplary soldiers are given an invitation by The Lieutenant, touted as the ultimate goal for a truly ambitious child of the barracks. At the end of each path is the same sad result, and the names of our fallen scroll on an infinite loop ‘cross enormous, digital banners. Joining our army is sold as a game; its leaderboards are gospel. How long can you last? How many postings? How many alien skulls have you cracked? That’s why the barracks are so well-maintained, and it irks the Immaculate Laboratory. How dare they achieve it with aggressive aplomb, without a spare second of thought for the science?',
    diseased_metropolis: 'Vetzsas Maias was once a proud capital, but now it holds that status out of pity. It’s a shaking, traumatised invalid, with a thriving media industry. I suppose, when I meet the Musicians of Maias, it’s easy to see why creativity blooms on the back of this ancient porpoise. We herd a brood of space-faring, ageless creatures, which we tamed, and built our finest cities upon; fuelled by the very blood of the beasts. Things went well for two-hundred generations; a symbiotic exchange took place. We fed the bioships, the bioships played host to our cities and no-one had cause to complain; there were just a few unfortunate disasters involving violent stomach upsets. Vetzsas — our name for the graceful alpha — was obedient in the extreme. She revelled in her journey, corralled by our hopes, and gladly skipped through nebulae, quasars, wormholes and rifts in the ever. She was attractive, precocious and rippling with promise, but that was destroyed by The Gator we found on the ring of some distant planet. Vetzsas sniffed it out, and waddled her way to the orbiting rock (and ice) that formed a coronal halo. They played for a while — the ‘dile spewed mile-wide torrents of twinkling star stuff. Impressed, our capital ark swished her tail (to show her ancillary exhausts at the rear). We’ve lived with disease ever since, on most of the ships with organic construction. Aiak’us reportedly slaughtered the rapist Gator by severing its golden head, beset — as it was — by a gravelly beard of crystal we cleverly utilised. Rumour has it, Salt of the Gator continues to season our Smog Roasts. But poor, gorgeous Vetzsas Maias — trusting, sweet and naive — is forced to suffer a virus, now endemic in the fleet.',
    biology: 'These haughty nannies — to our magnificent bioships — are almost useless in the vacuum of space, and always dream of Arrival. They claim to be busy with duties pertaining to the maintenance of creatures we ride; but, even diseased, the fleet’s organic contingent is largely autonomous. Instead, they follow the Terraforming Ark, constantly asking, “Are we there yet?” We have wild flora and fauna on-board our many residential environments. Indeed, conservation is the entire purview of the Greenbelt Citadel, Zaļā Yowusta. Evolution stagnates in space, it would seem, and our forests are totally catalogued. With no new species to track, catch and log, the biologists have gone rather mad. They once tried to land on a glorified spherical swamp — devoid of animal life — hoping their ancestors would keep up an interest in science and stay, to study the panspermic progeny of their careless avarice. Despite their aversion to The House of the Eye — our main religious centre — they’re often seen in the pews of its garden, praying to huge, bright murals of jungles. What will they do when they finally catch the ball they’ve chased for generations? I predict a certain level of whiplash; when battle occurs they’re the first to cloak in smog and hide with the Immaculate Laboratory. I doubt they’d act with the utmost bravery if faced with the slavering jaws of reality. Most of their ship is styled as a nature reserve; birds fly in the dome-sky, filled with clouds from the looping renewal of an artificial atmosphere. And yet, the biologists themselves rarely stray from their small, anterior lab.',
    main_engineering: 'The loss of Engineering’s port side nacelle is an example of our poor defensive net. Residential arks are protected by Aiak’us; the Lieutenant and his Pawns almost always circle Parliament. That leaves our Battle ‘Toise — hardened though it is — on patrol like a watchman, decrepit with age. To be fair to our generals, their instincts are right; our political and domestic districts are usually tempting targets to attackers. The smartest enemies aim for utilities, including The Office and Main Engineering. When a Pawn can’t deploy with requisite speed — and the ‘Toise is trapped on its rounds elsewhere — such ships are at risk, with only the most rudimentary on-board peashooters (to make a theatrical stand with). It’s a flaw in our tactics we freely admit, but we’re making the best of a limited military. Fifty generations ago, a wily pirate accosted our fleet. He was alone and opportunistic, with a swiftly failing polaric inverter. We’d have happily sold a replacement — at an inflated price, of course — but he cut off our hails and coasted towards the heart of our arks regardless. In the end, he made off with an entire nacelle; towed it through folded space with a tractor-beam! We don’t expect to see him again, though “Wanted” posters adorn the public bulletin boards in hope. After its amputation, this ark was set for demolition. We planned to promote a supplemental engineering ship, though the stubborn crew of this damaged husk refused to relinquish control. They were adamant they’d fix it with smog; that the loss of a leg is a minor disturbance. Today, the glaring absence of a sadly sheared nacelle serves as a nagging reminder of their empty promises',
    diseased_cargo_hold: 'Bioships regularly mate, it turns out, and — eight waves back — we manage a nursery. The young are easy to teach when threatened with vast, electric gates, while their parents are happy to leave them to us; fiercely independent (typical of their wretched species). In adulthood, their sexual proclivities are a real concern. We shield our cities with triple-layered deflectors; there to protect the inhabitants from the convulsing, rutting act of love and its stray flecks of excitement. That’s how the viral infection spread, from Vetzsas Maias to a cargo hold, then on through the rest of the fleet. We knew the hold had eyes for Vetzsas; he’d courted her with songs — telepathically performed in the silence of space — that dribbled into our dreams. A nice enough sort, we let him get on with it, encouraged by the rampant curiosity of biologists. They thought it important we test if the illness could be transmitted through sex. Once the moss of infection had grown upon the hold, to form wings, he turned his back on Vetzsas Maias and embraced bachelordom. Hindsight makes it clear we should have spayed our capital ark, or — at the very least — performed a vasectomy on the hold. His charming music proved a popular gimmick with his prospective partners. Thus, an emerald, venereal fungus followed his every conquest. The staff of this cargo hold are safe, but disgruntled; understandably so. They’re treated with caution, avoided in bars and prone to seclusion (where possible). It’s believed they’ve settled their ship’s gigantic libido — after all of these years — although the damage is done; the spawn of The Gator remains as a verdant blight on the skin of our finest companions.'
  },
  birdsong: {
    title: 'A Guide to the Birdsong of Central America, Mexico and the Caribbean',
    jamaican_blackbird: 'The Jamaican Blackbird is endemic to the island and unique amongst blackbirds as it feeds much like a woodpecker. It can only be found in the mountainous forests of Jamaica’s Cockpit Country, and in the John Crow and Blue Mountains. The species is considered Endangered mainly due to the threat posed to its habitat by Bauxite mining.',
    cotorr_serrana_occidental: 'The Thick-billed Parrot is found in the high pine, fir and conifer forests of Mexico’s Sierra Madre Occidental. It is classified as Endangered and its population is believed to be in rapid decline mainly due to the loss of old-growth forest to large-scale logging or agriculture. Trapping for the illegal pet trade also poses a threat. ',
    ferminia: 'The Zapata Wren is a highly secretive bird that is endemic to the island of Cuba. Inhabiting the dense scrub of the Zapata Swamp. The species is classified as Endangered and drainage of its wetland habitat alongside agricultural expansion has degraded this species’ habitat, while introduced mongooses and rats also pose a threat.',
    raso_lark: 'The species is classified as ‘Critically endangered’ with only 250 to 999 individuals left in the wild and, as a ground-nester, it has been vulnerable to the accidental introduction of predators, especially cats and rats. The Raso lark is a greyish streaky lark with a large heavy bill and a short crest. It is a species that can only be found along the dry riverbeds and flat arid plains of the tiny uninhabited islet of Raso, Cabo Verde. ',
    rufuous_fishing_owl: 'The Rufous fishing owl is an elusive bird endemic to the Upper Guinea Forests found between Sierra Leone and Ghana.  It lives along river and lagoon edges, where it hunts at night, feeding on fish, freshwater crabs and amphibians.  Pairs ‘duet’ with soft, dove-like low hoots.  Classified as ‘Vulnerable’, it is threatened by continuing clearance of mangrove and forest in the region.',
    sao_tome_grosbeak: 'The São Tomé grosbeak is a large pot-bellied, chestnut brown finch endemic to the primary forests of the highlands of this equatorial archipelago.  It travels in pairs or alone and comes to the forest understory to feed on seeds that it crushes with its powerful bill. Classified as ‘Critically Endangered’, it is threatened by habitat degradation and introduced predators.',
    ibadan_malimbe: 'The Ibadan malimbe is a rare species of bird that is endemic to Nigeria, where it is known only from the southwestern part of the country, including the city of Ibadan which it\'s named after. A striking red and black bird, it was first discovered in 1951 and is currently classified as \'Endangered\'. Its greatest threat is loss of habitat due to forest clearing.',
    timneh_parrot: 'The Timneh Parrot is a sociable forest-dwelling bird. Once found throughout the Upper Guinea forests, the species has disappeared from the majority of its range, and is currently classified as \'Endangered\'. The greatest threat to the Timneh Parrot is the pet trade; it is one of the most popular bird pets in Europe, North America and the Middle East.',
    mac_tobiano: 'The majestic Hooded Grebe inhabits the lakes and arid steppes of Chilean and Argentinean Patagonia. Its song is an other-worldly, rhythmic series of rising notes. The species has suffered, and continues to suffer from, an extremely rapid decline in its population - as much as by 40% since the 1990s. It is now known to breed at only a few locations in south-western Argentina.'
  },
  countdown: {
    day: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds'
  },
  auction_start: 'Time until start',
  auction_distance_end: 'Time until end',
  auction_over: 'Closed',
  auction_notstarted: 'Not started',
  current_price: 'Current prices：',
  highest_account: 'Highest bidder account：',
  nft_eth_collect_address: 'ETH Address：',
  nft_ar_collect_address: 'AR Address：',
  auction_mybid: 'My bid：',
  not_yet_started: 'Not yet started',
  auction_has_end: 'Check NFT information',
  bidding: 'Auction',
  collection_nft: 'Collect Winston Elephant Family Collection!',
  home_title: 'Collect everPay NFTs ',
  home_title2: ' can participate in Permaswap mining',
  home_title_info: 'Proceeds from the auction will be distributed to user, the artist and past NFT Holders. Come be apart of the bid wars!',
  bid_now: 'Bid Now',
  connection: 'Please wait for connection',
  fill_in_ethereum_address: 'Please fill in the NFT ethereum collection address.',
  fill_in_arweave_address: 'Please fill in the NFT arweave collection address.',
  fill_in_address_error: 'The address format is incorrect. please re-enter.',
  price_restrictions: 'Bids must not be less than 5% of the current price or more than 20% of the current price.',
  current_nft_count: 'You\'ve already bid on {num} NFTs and cannot bid on any more',
  nft_eth_collection_tips: 'everPay NFT will be released on the ethereum network and users must fill in an ethereum address.',
  nft_ar_collection_tips: 'everPay NFT will be released on the Arweave network and users must fill in an Arweave address.',
  nft_mybid_tips: '1. The minimum unit for bidding is 0.01 {symbol}; ',
  nft_mybid_tips2: '2. Bids must be no less than 5% of the current price and no more than 20% of the current price.',
  already_bid_normal: 'Bid',
  num_times: 'times',
  already_bid: 'Already bid {bidTimes} times',
  current_account: 'Current account balance insufficient',
  top_up_now: 'Top up now',
  pls_input_ethereum_addr: 'Please input Ethereum address',
  pls_input_arweave_addr: 'Please input Arweave address',
  pls_input_enter_nft_addr: 'Please enter NFT collection address.',
  auction_lose: 'Unsuccessful bid',
  participate_auction: 'Share NFT auctions with others and earn referral rewards! When a referred participates in auction through your link, you will receive 0.5% of the referrer\'s bid amount as a referral reward.',
  referral_Bonus: 'Earn Referral Bonus!',
  copy_link: 'Copy Link',
  share_twitter: 'Share to Twitter',
  auction_proceeds: 'Proceeds: ',
  recommend_proceeds: 'Recommended: ',
  currently_auctioned: 'Current Bids: ',
  white_list_congratulations: 'Congratulations! You can bid {minutes} minutes earlier!',
  bidding_history: 'View Bidding History',
  nfttitle_auction: 'NFT Auction',
  twitter_chare: 'Hey! Click on the link to join the feeless auction on everPay for exclusive NFTs. If you join using the link you\'ll get an extra 0.5 percent on top of the 102 percent if you get outbid! Who wants an Arweave mascot 😀?! Auction dates: 6th to 13th of Jan!',
  twitter_tiger_chare: 'Hey! Join everPays feeless auction to earn NFTs with utility. Joining using the link will get you an extra 0.5 percent for a total refund of up to 104 percent if you get outbid. Make sure to use =BitKeepOS for an extra 1 percent! Date: 27th-28th Jan.',
  twitter_dragon_turtles_chare: 'Take part in everPays and =ardriveapp NFT auction to win cute dragon turtle NFTs! Each NFT is an entry ticket to Permaswap liquidity mining. Bid on NFTs via my referral link!',
  twitter_con_fi_city_chare: 'Take part in everPay and =Conflux_Network NFT auction to win cute ConFi NFTs! Each NFT is an entry ticket to Permaswap liquidity mining. Bid on NFTs via my referral link!',
  socials_account: 'Socials:',
  min_price: 'Click here to bid the minimum',
  view_ori_batch_nfts: 'View previous NFT batches.',
  new_auction_nfts: 'View Latest NFT Auctions',
  distribution: 'Distribution:',
  floorPrice: 'Floor Price:',
  volume: 'Total Volume:',
  enter: 'Enter',
  not_start: 'Not started',
  event_progress: 'In progress',
  auction_closed: 'Auction ends',
  service_not_available: 'the service is not available in your region.',
  price_increase: 'Current mark-up amount',
  confirmation: 'Confirm',
  interest_rate: 'Price increase {interestRate}',
  nft_num_status: 'Just get outbid by others and you can re-enter the auction right after',
  sort_nft: 'Sort by',
  filter_nft: 'Selection method',
  price_high_low: 'Price descending',
  price_low_high: 'Price ascending',
  filter_not_end: 'Not closed NFT',
  filter_end: 'Closed NFT',
  please_select: 'Please select an option',
  remove_sort: 'Remove',
  distance_to_end: 'Ends in:',
  end_hours: '{hours}h',
  end_minutes: '{minutes}m',
  please_wait: 'Please wait',
  cooling_in_progress: 'Cooldown',
  past_auctions: 'Past Auctions',
  back_prev_page: 'Back to previous page',
  back_to_auction_home: 'Back to homepage',
  nft_hot_tip: 'This NFT is currently in a bid war, try bidding on another NFT.🔥🔥🔥',
  nft_address_err_tip: 'Please input an Arweave address. If you still use an Ethernet address, and successfully obtain an NFT, please contact us ({email})!',
  understand: 'I understand!',
  price_cap: 'Price reaches test limit',
  price_cap_tips: 'The price of this NFT has reached the testing price limit, you can bid on other NFTs!',
  tutorial: 'Tutorial',
  tutorial_info: 'Auction is so simple!',
  view_video: 'View Video',
  how_get_arweave_address: 'How to get Arweave address?',
  faucet_page: {
    title: 'Faucet',
    msg1: 'everPay: real-time financial protocol for the blockchain.',
    msg2: 'Claim test token in just two steps.',
    msg3: 'Claim test token once every 24 hours per address.',
    input_address: 'Recipient',
    evm_ar_address: 'Input Ethereum / Arweave / Email format address',
    please_address: 'Input address',
    post_the_tweet: 'Post the tweet',
    please_tweet_msg: 'You will need to send a tweet from an active Twitter account.',
    open_twitter: 'Open Tweet Pop-Up',
    format_err: 'Format Error',
    unable_to_collect: 'This address has already claimed test token this today, Please come back in {time}  to claim.',
    claim_success: 'Claim successful',
    claim_failed: 'Claim failed',
    submit: 'Submit',
    pending: 'Pending...',
    everscan_view: 'Go to everScan to view',
    error: {
      err_recaptcha_failed: 'Recaptcha Failed',
      err_acc_not_exist: 'Receiving address is not registered for collection',
      err_invalid_id: 'Invalid id'
    }
  }
}
export default messages
