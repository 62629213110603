import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled", "loading"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    style: {"height":"48px","line-height":"48px"},
    class: _normalizeClass(["w-full flex flex-row items-center justify-center rounded-md text-base focus:outline-none text-white", _ctx.disabled || _ctx.loading ? 'cursor-default bg-btnBackGround' : 'cursor-pointer bg-everPurple4']),
    disabled: _ctx.disabled,
    loading: _ctx.loading,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "w-6 h-6 mr-1 animate-spin-slow",
          src: require('../../images/icon-loading-white.png'),
          alt: "white-loading"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}