import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/images/icon-loading-purple.png'
import _imports_1 from '@/images/icon-success.png'


const _hoisted_1 = { class: "text-12px" }
const _hoisted_2 = { class: "text-12px" }
const _hoisted_3 = { class: "text-12px" }
const _hoisted_4 = { class: "text-everPurple2 text-14px font-medium" }
const _hoisted_5 = { class: "text-everGray6 text-12px font-normal" }
const _hoisted_6 = { class: "bg-faucetInpte text-everPurple2 mt-2 border border-solid px-3 h-11 flex items-center justify-center rounded-md border-faucetInpteBorder" }
const _hoisted_7 = ["disabled", "placeholder"]
const _hoisted_8 = {
  key: 0,
  src: _imports_0,
  class: "w-6 h-6 ml-2 animate-spin-slow",
  alt: "purple-loading"
}
const _hoisted_9 = {
  key: 1,
  src: _imports_1,
  class: "w-6 h-6 ml-2",
  alt: "icon-success"
}
const _hoisted_10 = {
  key: 0,
  class: "text-everRed text-12px mt-1"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = {
  class: "mt-6",
  style: {"height":"78px"}
}
const _hoisted_16 = {
  id: "grecaptcha",
  class: "px-auto grecaptcha"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notice_item = _resolveComponent("notice-item")!
  const _component_notice_list = _resolveComponent("notice-list")!
  const _component_BtnArea = _resolveComponent("BtnArea")!
  const _component_container = _resolveComponent("container")!

  return (_openBlock(), _createBlock(_component_container, {
    title: _ctx.t('faucet_page.title'),
    class: "mt-32"
  }, {
    noticelist: _withCtx(() => [
      _createVNode(_component_notice_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_notice_item, { "list-style-disc": true }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('faucet_page.msg1')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_notice_item, { "list-style-disc": true }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('faucet_page.msg2')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_notice_item, { "list-style-disc": true }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('faucet_page.msg3')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('faucet_page.input_address')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('faucet_page.evm_ar_address')), 1),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputAddress) = $event)),
            type: "text",
            disabled: _ctx.isInputDisaled,
            class: "h-full flex-1 w-full text-14px bg-transparent",
            placeholder: _ctx.t('faucet_page.please_address'),
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputChange && _ctx.inputChange(...args)))
          }, null, 40, _hoisted_7), [
            [
              _vModelText,
              _ctx.inputAddress,
              void 0,
              { trim: true }
            ]
          ]),
          (_ctx.addressTxloading)
            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
            : (_ctx.isCollection)
              ? (_openBlock(), _createElementBlock("img", _hoisted_9))
              : _createCommentVNode("", true)
        ]),
        (_ctx.inputAddress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (!_ctx.addressformatSuccess)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.t('faucet_page.format_err')), 1))
                : (!_ctx.isCollection && _ctx.addressTxTimer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('faucet_page.unable_to_collect', {time: _ctx.addressTxTimer })), 1),
                      (_ctx.addressTx?.everHash)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "text-black underline underline-offset-2",
                            href: `https://scan${_ctx.isProd ? '' : '-dev'}.everpay.io/tx/${_ctx.addressTx.everHash}`
                          }, _toDisplayString(_ctx.t('faucet_page.everscan_view')), 9, _hoisted_13))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_14))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _withDirectives(_createElementVNode("div", _hoisted_16, null, 512), [
          [_vShow, _ctx.grecaptchaVisible && _ctx.isCollection]
        ])
      ]),
      _createVNode(_component_BtnArea, {
        loading: _ctx.submitLoading,
        class: "mt-16",
        disabled: !_ctx.isCollection || !_ctx.addressformatSuccess || !_ctx.v2TokenStr,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isCollection && _ctx.addressformatSuccess && _ctx.v2TokenStr ? _ctx.submitVerify() : ''))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.submitLoading ? _ctx.t('faucet_page.pending') : _ctx.t('faucet_page.submit')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled"])
    ]),
    _: 1
  }, 8, ["title"]))
}